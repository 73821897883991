import LivetoLoader from "Components/loader/livetoLoader";
import { useState } from "react";
import { useEffect } from "react";
import { Localized } from "@fluent/react";
import './apiCallHandler.scss';


const ErrorMessage = props => {
    const { status, error } = props;

    const readableError = error ? error : ERRORMESSAGES[status];

    return <div className="api-handler">
        <div className="status">{status}</div>
        {readableError ? readableError : <Localized id="wrapper-default-error"/>}
    </div>
}

const ERRORMESSAGES = {
    401: 'Unauthorized',
    402: 'Payment required',
    403: 'Forbidden',
    404: 'Not found',
}

const AsyncHandler = (props) => {
    const { loading, status, error = null, size = "30" } = props;
    const [innerLoading, setInnerLoading] = useState(false);
    let timer = null;
    useEffect(() => {
        if (loading) {
            setInnerLoading(loading);
        }
        if (innerLoading && !loading) {
            timer = setTimeout(() => {
                setInnerLoading(false);
                clearTimeout(timer);
            }, 100)
        }
    }, [loading])
    if (innerLoading) {
        return <div className="api-handler">
            <LivetoLoader size={size} />
        </div>
    } else {
        if (status === 200) {
            return props.children
        }
        if (status === null) {
            return null;
        }
        return <ErrorMessage status={status} error={error} />
    }
}
export default AsyncHandler