/** @format */

import { Localized } from "@fluent/react";
import { useLocalStorage, useSetState } from "@mantine/hooks";
import LoadPage from "Components/loadPage/loadPage";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { getBoothDataFromDatabase } from "indexedDb/dexie";
import {
	getEventsRecordsFromDatabase,
	getScanModeOptionsFromIndexedDb,
	getScannerConfigurationFromDatabase,
	getScanSettingsFromDatabase,
	getUserSettingsFromDatabase,
	modifyScanModeOptionsInIndexedDb,
	modifyScanSettingsRecordInDatabase,
	modifyUserSettingRecordInDatabase,
	resetUserSettingsRecordInDatabase,
	setConfigurationToDatabase,
} from "indexedDb/dexie";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { LocaleContext } from "util/localizationProvider";

export const ConfigurationsContext = createContext({});

const initialScannerConfiguration = {
	title: "",
	expiration_time: "",
	apikey: "",
	events: [],
	device_name: "",
};

const initialScanSettings = {
	scanDelay: 3000,
	keepScanPopupOpen: false,
	logging: false,
	autoUpdate: true,
	updateInterval: 60000,
	device_name: "",
	muted: false,
};

const initialUserSettings = {
	language: "fi",
	handedness: "right",
	wakelock: false,
};

const initialScanMode = {
	mode: "entry",
	section: null,
	sectionName: null,
	timeTolerance: "0",
	expiredTicketAcceptance: false,
};
const boothSettings = {
	allowed_booth_names: [],
	booth_mode_only: false,
};

const ConfigurationsContextProvider = ({ children }) => {
	const { setLanguage } = useContext(LocaleContext);

	const [initialized, setInitialized] = useState(false);

	// Scanner configuration
	const [scannerConfiguration, setScannerConfiguration] = useSetState(initialScannerConfiguration);
	const [events, setEvents] = useState([]);
	const [boothOptions, setBoothOptions] = useState({});

	// Scanner settings
	const [scanSettings, setScanSettings] = useState(initialScanSettings);

	// User settings
	const [userSettings, setUserSettings] = useState(initialUserSettings);

	// Scan options, which section / entry your scanning..
	const [scanMode, setScanMode] = useState(initialScanMode);

	async function persistScannerConfigurations(configuration, events, booth) {
		const { status, data } = await setConfigurationToDatabase({
			configuration: configuration,
			events: events,
			booth: booth,
		});

		if (status === DB_RESPONSE_OK) {
			setScannerConfiguration({ ...scannerConfiguration, ...data.configuration });
			setEvents(data.events);
			setBoothOptions(data.boothOptions);
		}
		return { status, data };
	}

	// Device settings

	// Scanning mode modifications
	async function modifyScanMode(data) {
		const { status, data: dbData } = await modifyScanModeOptionsInIndexedDb(scanMode.id, data);
		if (status === DB_RESPONSE_OK) {
			setScanMode(dbData);
		}
		return { status, data };
	}

	// Generic user specific scanning settings
	async function modifyScanSettings(key, value) {
		const { status, data } = await modifyScanSettingsRecordInDatabase(scanSettings.id, key, value);

		if (status === DB_RESPONSE_OK) {
			setScanSettings(data);
		}
		return { status, data };
	}

	// User specific usability settings
	async function modifyUserSetting(key, value) {
		const { status, data } = await modifyUserSettingRecordInDatabase(userSettings.id, key, value);
		if (status === DB_RESPONSE_OK) {
			setUserSettings(data);
			if (key === "language") {
				setLanguage(data.language);
			}
		}
	}

	async function initializeConfiguration() {
		const userSettingsResponse = await getUserSettingsFromDatabase(initialUserSettings);
		if (userSettingsResponse.status === DB_RESPONSE_OK) {
			setUserSettings({ ...userSettings, ...userSettingsResponse.data });
		}

		const scanSettingsResponse = await getScanSettingsFromDatabase(initialScanSettings);
		if (scanSettingsResponse.status === DB_RESPONSE_OK) {
			setScanSettings({ ...scanSettings, ...scanSettingsResponse.data });
		}

		const scanModeSettingsResponse = await getScanModeOptionsFromIndexedDb(initialScanMode);
		if (scanModeSettingsResponse.status === DB_RESPONSE_OK) {
			setScanMode({ ...scanMode, ...scanModeSettingsResponse.data });
		}

		// Scanner configuration specific data
		const scanConfResponse = await getScannerConfigurationFromDatabase();
		if (scanConfResponse.status === DB_RESPONSE_OK) {
			setScannerConfiguration(scanConfResponse.data);

			const eventsResponse = await getEventsRecordsFromDatabase();
			if (eventsResponse.status === DB_RESPONSE_OK) {
				setEvents(eventsResponse.data);
			}

			const boothResponse = await getBoothDataFromDatabase(scanConfResponse.data.apikey);
			if (boothResponse.status === DB_RESPONSE_OK) {
				setBoothOptions(boothResponse.data);
			}
		}

		setInitialized(true);
	}

	useEffect(() => {
		initializeConfiguration();
	}, []);

	const ctx = {
		scannerConfiguration,
		persistScannerConfigurations,
		userSettings,
		setUserSettings,
		modifyUserSetting,
		scanSettings,
		modifyScanSettings,
		initializeConfiguration,
		initialized,
		boothMode: boothOptions.booth_mode_only || false,
		allowedBoothNames: boothOptions.allowed_booth_names || [],
		events: events,
		scanMode,
		modifyScanMode,
	};

	async function handleWakelock() {
		if (userSettings.wakelock) {
			await navigator.wakeLock.request();
		} else {
			//await navigator.wakeLock.release();
		}
	}

	useEffect(() => {
		if (navigator.wakeLock) {
			handleWakelock();
		}
	}, [userSettings.wakelock]);

	if (!initialized) {
		return <LoadPage text={<Localized id="app-loading" />} />;
	}

	return <ConfigurationsContext.Provider value={ctx}>{children || null}</ConfigurationsContext.Provider>;
};

export default ConfigurationsContextProvider;
