import { IconChevronRight, IconCog, IconInfo, IconQrCode, IconUsers } from 'Components/icons/icons';
import Paragraph from 'Components/onboard/paragraph';
import { ConfigurationsContext } from 'Context/configurations';
import { useContext } from 'react';
import * as Lvt from 'StyledComponents/components';
import * as paths from 'constants/paths';

import './home.scss';
import { useParams } from 'react-router';
import { Localized } from '@fluent/react';

const BoothHomeView = () => {
    const { apikey } = useParams();

    const { scanSettings } = useContext(ConfigurationsContext);
    const { device_name } = scanSettings;

    return <div id="home">
        <h1>
            <Localized id="booth-home-hey" vars={{ exhibitor: device_name }} />
        </h1>
        <Paragraph>
            <Localized id="booth-home-instructions" />
        </Paragraph>
        <div className='button-container'>

            <Lvt.Link to={paths.boothScanPath(apikey)} className="nav-button">
                <IconQrCode size="25" className="button-icon" />
                <Localized id="booth-home-scan" />
                <IconChevronRight className="chevron" />
            </Lvt.Link>
            <Lvt.Link to={paths.boothScannedTicketsPath(apikey)} className="nav-button">
                <IconUsers size="25" className="button-icon" />
                <Localized id="booth-home-scans" />
                <IconChevronRight className="chevron" />
            </Lvt.Link>
            <Lvt.Link to={paths.settingsPath(apikey)} className="nav-button">
                <IconCog size="25" className="button-icon" />
                <Localized id="booth-home-settings" />
                <IconChevronRight className="chevron" />
            </Lvt.Link>
        </div>


    </div>
}
export default BoothHomeView;