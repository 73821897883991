import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import Paragraph, { OnboardNextPhaseTip } from "Components/onboard/paragraph";
import { appOnboardConfigurationInfoPath, appOnboardReadConfigPath } from "constants/paths";
import OnboardWrapper from "Components/onboard/onboardWrapper";
import { Localized } from "@fluent/react";

import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { scannerConfigurationUrl } from "constants/apiurls";
import { encodeQueryParams } from "util/api";


const AppSetupConfig = () => {
    const { apikey = "" } = useParams();
    const history = useHistory();

    async function fetchConfiguration() {
        const resp = await fetch(`${scannerConfigurationUrl}${encodeQueryParams({ apikey: apikey })}`)
        if (resp.ok) {
            history.push(appOnboardConfigurationInfoPath(apikey));
        }
    }
    useEffect(() => {
        if (apikey) {
            fetchConfiguration();
        }
    }, [])

    return <OnboardWrapper>
        <OnboardHeading>
            <Localized id="onboard-config-setup-heading" />
        </OnboardHeading>

        <Paragraph>
            <Localized id="onboard-config-setup-paragraph-1" />
        </Paragraph>

        <Paragraph>
            <Localized id="onboard-config-setup-paragraph-2" />
        </Paragraph>

        <ForwardButton to={appOnboardReadConfigPath(apikey)} />

        <OnboardNextPhaseTip>
            <Localized id="onboard-config-setup-next-tip" />
        </OnboardNextPhaseTip>

    </OnboardWrapper>
}

export default AppSetupConfig;