/** @format */

import { NavLink } from "react-router-dom";

import "scss/components/liveto-components.scss";

export const Container = props => {
	const { type = "auto", children, className: classNames = "", ...rest } = props;
	return (
		<div className={`lvt-container-${type}${classNames ? ` ${classNames}` : ""}`} {...rest}>
			{children}
		</div>
	);
};

export const Link = props => {
	const { children, type = "link", variant = "md", className: classNames = "", disabled = false, ...rest } = props;

	function onClick(e) {
		if (disabled) {
			e.preventDefault();
		}
	}

	// Style defining
	let styles = `lvt-link-${type} ${variant}`;
	styles = classNames ? `${styles} ${classNames} ` : styles;

	return (
		<>
			<NavLink onClick={e => onClick(e)} className={styles} {...rest} disabled={disabled}>
				{children}
			</NavLink>
		</>
	);
};
export const Text = props => {
	const { children, set = "p", className: classNames = "", ...rest } = props;
	const className = `lvt-text-${set} ${classNames ? ` ${classNames}` : ""} `;
	const textProps = { className, ...rest };
	switch (set) {
		case "p":
			return <p {...textProps}>{children}</p>;
		case "small":
			return <small {...textProps}>{children}</small>;
		case "strong":
			return <strong {...textProps}>{children}</strong>;
		case "h1":
			return <h1 {...textProps}>{children}</h1>;
		case "h2":
			return <h2 {...textProps}>{children}</h2>;
		case "h3":
			return <h3 {...textProps}>{children}</h3>;
		case "h4":
			return <h4 {...textProps}>{children}</h4>;
		case "h5":
			return <h5 {...textProps}>{children}</h5>;
		case "h6":
			return <h6 {...textProps}>{children}</h6>;
		default:
			return <p {...textProps}>{children}</p>;
	}
};

export const Btn = props => {
	const { children, variant = "md", type = "primary", className: classNames = "", ...rest } = props;
	return (
		<button className={`lvt-btn-${type} ${variant} ${classNames ? ` ${classNames}` : ""} `} {...rest}>
			{children}
		</button>
	);
};

export const Card = props => {
	const { className: classNames = "", children, ...rest } = props;

	return (
		<div className={`lvt-card${classNames ? ` ${classNames}` : ""} `} {...rest}>
			{children || null}
		</div>
	);
};

export const CardHead = props => {
	const { className: classNames = "", children, ...rest } = props;
	return <div className={`lvt-card-head${classNames ? ` ${classNames}` : ""} `}>{children || null}</div>;
};

export const CardBody = props => {
	const { className: classNames = "", children, ...rest } = props;
	return <div className={`lvt-card-body${classNames ? ` ${classNames}` : ""} `}>{children || null}</div>;
};
export const CardFoot = props => {
	const { className: classNames = "", children, justify = "start", ...rest } = props;
	return (
		<div className={`lvt-card-foot lvt-justify-${justify} ${classNames ? ` ${classNames}` : ""} `}>
			{children || null}
		</div>
	);
};

export const Row = props => {
	const { className: classNames = "", justify = "start", align = "center", children, ...rest } = props;

	const styles = `lvt-row justify-${justify} align-${align}${classNames ? ` ${classNames}` : ""}`;
	return (
		<div className={styles} {...rest}>
			{children || null}
		</div>
	);
};

export const Col = props => {
	const { className: classNames = "", justify = "start", align = "start", children, ...rest } = props;

	return (
		<div
			className={`lvt-col
    justify-${justify}
    align-${align}
    ${classNames ? ` ${classNames}` : ""} `}
			{...rest}
		>
			{children || null}
		</div>
	);
};

export const TextInput = props => {
	const { children, className: classNames = "", ...rest } = props;
	return <input type="text" className={`lvt-input-text${classNames ? ` ${classNames}` : ""} `} {...rest} />;
};

export const Select = props => {
	const { children = null, className: classNames = "", ...rest } = props;
	return (
		<select className={`lvt-input-select${classNames ? ` ${classNames}` : ""} `} {...rest}>
			{children}
		</select>
	);
};
