/** @format */

import { Localized } from "@fluent/react";
import { IconQrCode } from "Components/icons/icons";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfigurationsContext } from "Context/configurations";
import { useCallback } from "react";

import useSound from "use-sound";
import alreadyScannedFX from "sounds/error2.mp3";
import successFX from "sounds/success.mp3";
import errorFX from "sounds/error.mp3";

import "./statusRenderer.scss";

const STATUS_TO_CLASS = {
	0: "failed",
	1: "scanned",
	2: "success",
};

const showValidityText = (ticket, product = {}) => {
	const { valid_from: ticket_valid_from = null, valid_until: ticket_valid_until = null } = ticket;
	const { valid_from: product_valid_from = null, valid_until: product_valid_until = null } = product;
	const ticketValidFrom = moment(ticket_valid_from).format("DD.MM.YYYY HH:mm");
	const ticketValidUntil = moment(ticket_valid_until).format(`DD.MM.YYYY HH:mm`);
	const productValidFrom = moment(product_valid_from).format("DD.MM.YYYY HH:mm");
	const productValidUntil = moment(product_valid_until).format(`DD.MM.YYYY HH:mm`);

	if (ticket_valid_from && ticket_valid_until) {
		return (
			<Localized
				id={`scanview-status-ticket-validity-fromuntil`}
				vars={{ time: `${ticketValidFrom} - ${ticketValidUntil}` }}
			/>
		);
	}

	if (ticket_valid_from) {
		return <Localized id={`scanview-status-ticket-validity-from`} vars={{ time: ticketValidFrom }} />;
	}

	if (ticket_valid_until) {
		return <Localized id={`scanview-status-ticket-validity-until`} vars={{ time: ticketValidUntil }} />;
	}

	if (product_valid_from && product_valid_until) {
		return (
			<Localized
				id={`scanview-status-ticket-validity-fromuntil`}
				vars={{ time: `${productValidFrom} - ${productValidUntil}` }}
			/>
		);
	}

	if (product_valid_from) {
		return <Localized id={`scanview-status-ticket-validity-from`} vars={{ time: productValidFrom }} />;
	}

	if (product_valid_until) {
		return <Localized id={`scanview-status-ticket-validity-until`} vars={{ time: productValidUntil }} />;
	}

	// Ticket actually exists
	if (Object.keys(ticket).length > 0) {
		return <Localized id={`scanview-status-ticket-validity-always`} />;
	}
	return "";
};

const StatusRenderer = ({ status, resetStatus }) => {
	const { ticket = {}, flag = 0, product = {} } = status;
	const { title = "" } = ticket;

	const { scanSettings } = useContext(ConfigurationsContext);
	const { muted } = scanSettings;

	const soundProps = { interrupt: true, volume: muted ? 0 : 1 };
	const [playError, { duration: errorDuration }] = useSound(errorFX, soundProps);
	const [playAlreadyScanned, { duration: scannedDuration }] = useSound(alreadyScannedFX, soundProps);
	const [playSuccess, { duration: successDuration }] = useSound(successFX, soundProps);

	const [timeToShow, setTimeToShow] = useState(10);

	const statusType = STATUS_TO_CLASS[flag];
	let validityText = showValidityText(ticket, product);

	const [initialized, setInitialized] = useState(false);

	const intervalRef = useRef(null);

	function clearStatus() {
		resetStatus();
	}

	const tickTime = useCallback(() => {
		setTimeToShow(prevTime => prevTime - 1);
	}, [timeToShow]);

	useEffect(() => {
		if (timeToShow === 0) {
			clearInterval(intervalRef.current);
			resetStatus();
		}
	}, [timeToShow]);

	useEffect(() => {
		intervalRef.current = setInterval(tickTime, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	useEffect(() => {
		if (scannedDuration && errorDuration && successDuration) {
			setInitialized(true);
		}
	}, [scannedDuration, errorDuration, successDuration]);

	useEffect(() => {
		if (status.flag === 0 && initialized) playError();
		if (status.flag === 1 && initialized) playAlreadyScanned();
		if (status.flag === 2 && initialized) playSuccess();
	}, [initialized]);

	return (
		<div id="scanner-status" className={statusType}>
			<h1>
				<Localized id={`scanview-read-${statusType}`} />
			</h1>
			<p>{status.msg}</p>

			{title && <b>{title}</b>}

			<p className="ticket-valid-for">{validityText}</p>

			<button className="close-status-button" onClick={() => clearStatus()}>
				<Localized id="qr-reader-status-close" />
			</button>
			<small>
				<Localized id="qr-reader-autoclose" vars={{ seconds: timeToShow }} />
			</small>
		</div>
	);
};

export default StatusRenderer;
