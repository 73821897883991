import { Localized } from '@fluent/react';
import { IconChevronLeft } from 'Components/icons/icons';
import { userSettingsPath } from 'constants/paths';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams, matchPath } from 'react-router';
import './settingsHeader.scss';
const SettingsHeader = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    const pageTitles = { // Korvaa localized avaimilla
        '/settings/:apikey': "settings-heading",
        '/settings/:apikey/user-settings': "user-settings-heading",
        '/settings/:apikey/app-settings': "app-settings-heading",
        '/settings/:apikey/device-info': "device-info-settings-heading",
        '/reset-application/:apikey': 'reset-app-heading',
        '/refetch-data/:apikey': 'refetch-heading',
    }

    const getPageTitleFromUrl = (url) => {
        const currentPageTitle = Object.keys(pageTitles).find((key) => {
            if (matchPath(url, { path: key, exact: true })) {
                return true
            }

            return false
        })

        return pageTitles[currentPageTitle]
    }


    return <div id="settings-header">
        <button onClick={() => history.goBack()}>
            <IconChevronLeft />
            <Localized id="settings-header-back"/>
        </button>
        <h1>
            <Localized id={getPageTitleFromUrl(pathname)} />
        </h1>
    </div>
}



export default SettingsHeader;