/** @format */

import React, { forwardRef, useEffect, useCallback, useImperativeHandle, useRef, useState } from "react";
import { BrowserQRCodeReader } from "@zxing/browser";
import { BarcodeFormat, BinaryBitmap, ChecksumException, DecodeHintType } from "@zxing/library";
import "./scanner.scss";
import { IconQrCode } from "Components/icons/icons";
import ScanModeHumanReadable from "views/scannerApplication/scan/scanModeHumanReadable";
import { Localized } from "@fluent/react";
import { hapticFeedback } from "util/vibrate";

// https://github.com/jamenamcinteer/react-qr-barcode-scanner/blob/main/src/BarcodeScannerComponent.tsx

// IOS Camera problems on PWA: https://developer.apple.com/forums/thread/724683

const QRReader = forwardRef(({ onUpdate, blocked = false, readerStatusCallback }, ref) => {
	// Camera reader stuff
	const decoderRef = useRef(null);
	const blockedRef = useRef(false);
	const mediaRef = useRef(null);
	const playerRef = useRef(null);
	const clickDisabled = useRef(false);
	const controlsRef = useRef(null);

	const [reading, setReading] = useState(false);

	const [error, setError] = useState(null);

	const [torch, setTorch] = useState(false);

	const hints = new Map();
	hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX]);
	hints.set(DecodeHintType.TRY_HARDER, true);
	const browserCodeReaderOptions = {
		delayBetweenScanAttempts: 50,
		delayBetweenScanSuccess: 500,
		tryPlayVideoTimeout: 50,
	};

	useEffect(() => {
		if (!blocked) {
			blockedRef.current = false;
		}
	}, [blocked]);

	useImperativeHandle(
		ref,
		() => {
			return {
				startReader() {
					activateReader();
				},
				stopReader() {
					deactivateReader();
				},
				readerActive() {
					return decoderRef.current ? true : false;
				},
				torch: torch,
				toggleTorch() {
					setTorch(!torch);
				},
			};
		},
		[torch]
	);

	async function activateReader() {
		if (clickDisabled.current === true) {
			return;
		}

		try {
			clickDisabled.current = true;

			if (mediaRef.current) {
				mediaRef.current.getTracks().forEach(track => {
					track.stop();
				});
			}

			mediaRef.current = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: "environment", width: 1280, height: 720, focusMode: "continuous" },
				audio: false,
			});

			playerRef.current.srcObject = mediaRef.current;
			decoderRef.current = new BrowserQRCodeReader(hints, browserCodeReaderOptions);
			controlsRef.current = await decoderRef.current.decodeFromVideoDevice(
				undefined,
				playerRef.current,
				(result, err, c) => {
					if (result && !blockedRef.current) {
						blockedRef.current = true;
						onUpdate(null, { ...result });
						c.stop();
					}
				}
			);
			setError(null);
			setReading(true);
			if (readerStatusCallback) {
				readerStatusCallback(true);
			}
		} catch (err) {
			console.error("Error in activating QR reader", err);
			setError(<Localized id="qr-reader-camera-error" />);
		}
	}

	async function deactivateReader() {
		clickDisabled.current = false;
		if (!decoderRef.current) {
			console.error("Reader not active!");
		}

		if (controlsRef.current) {
			controlsRef.current.stop();
		}

		if (mediaRef.current) {
			mediaRef.current.getTracks().forEach(track => {
				track.stop();
			});
			mediaRef.current = null;
		}
		setReading(false);
		if (readerStatusCallback) {
			readerStatusCallback(false);
		}
	}

	useEffect(() => {
		return () => {
			if (mediaRef.current) {
				mediaRef.current.getTracks().forEach(track => {
					track.stop();
				});
				mediaRef.current = null;
			}
			if (controlsRef.current) {
				controlsRef.current.stop();
			}
			if (readerStatusCallback) {
				readerStatusCallback(false);
			}
		};
	}, []);

	useEffect(() => {
		if (typeof torch === "boolean" && navigator?.mediaDevices?.getSupportedConstraints().torch) {
			const track = mediaRef?.current?.getVideoTracks()[0];
			if (track && track.getCapabilities().torch && !track.getConstraints().torch) {
				track.applyConstraints({ advanced: [{ torch }] }).catch(err => onUpdate(err));
			}
		}
	}, [torch]);

	return (
		<div id="qr-reader-container">
			<video
				muted
				playsInline
				autoPlay
				id="camera-feed"
				className={reading ? "visible" : "hidden"}
				ref={playerRef}
			/>

			<div id="reader-menu" className={reading ? "hidden" : "visible"} onClick={() => activateReader()}>
				<h1>
					<Localized id="qr-reader-start" />
				</h1>
				<IconQrCode size="200" className="icon" />
				{error && <h1 className="error-text">{error}</h1>}
			</div>
		</div>
	);
});

export default QRReader;
