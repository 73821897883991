/** @format */

import { IconTimes } from "Components/icons/icons";
import { useContext, useState } from "react";
import { ConfigurationsContext } from "Context/configurations";

import "./scanOptionsDrawer.scss";
import { Localized } from "@fluent/react";
import Select from "Components/select/select";
import ReactSwitch from "react-switch";

const ScanOptionsDrawer = ({ open, setOpen }) => {
	const { events, scanMode, modifyScanMode } = useContext(ConfigurationsContext);

	const { timeTolerance, mode, section, expiredTicketAcceptance } = scanMode;
	const [selectedExpTicketAcceptance, setSelectedExpTicketAcceptance] = useState(expiredTicketAcceptance);
	const [selectedTimeTolerance, setSelectedTimeTolerance] = useState(timeTolerance);
	const [selectedMode, setSelectedMode] = useState(mode);
	const [selectedSection, setSelectedSection] = useState(section);

	function findVenueSection(sectionId) {
		for (let event of events) {
			if (event?.venuemap?.sections && event.venuemap.sections.length > 0) {
				for (let section of event.venuemap.sections) {
					if (section.id === parseInt(sectionId)) {
						return section;
					}
				}
			}
		}

		return null;
	}

	function saveChanges() {
		const payload = { mode: selectedMode };

		if (selectedTimeTolerance !== scanMode.timeTolerance) {
			payload["timeTolerance"] = selectedTimeTolerance;
		}
		if (selectedExpTicketAcceptance !== scanMode.expiredTicketAcceptance) {
			payload["expiredTicketAcceptance"] = selectedExpTicketAcceptance;
		}

		// Reset section info when entry is selected
		if (selectedMode === "entry") {
			payload["section"] = null;
			payload["sectionName"] = null;
		} else {
			const section = findVenueSection(selectedSection);
			payload["section"] = section.id;
			payload["sectionName"] = section.name;
		}
		modifyScanMode(payload);
		setOpen(false);
	}

	function buttonDisabled() {
		const toleranceChanged = timeTolerance !== selectedTimeTolerance;
		const expiredTicketAcceptanceChanged = expiredTicketAcceptance !== selectedExpTicketAcceptance;
		const modeChanged = mode !== selectedMode;
		const sectionChanged = section != selectedSection;

		// Section option is selected, but not specific section is selected -> Disable button
		if (selectedMode === "section" && selectedSection === null) {
			return true;
		}

		// Nothing has changed, disable button
		if (!toleranceChanged && !modeChanged && !sectionChanged && !expiredTicketAcceptanceChanged) {
			return true;
		}

		return false;
	}

	function close() {
		setOpen(false);
		setSelectedMode(mode);
		setSelectedSection(section);
		setSelectedTimeTolerance(timeTolerance);
		setSelectedExpTicketAcceptance(expiredTicketAcceptance);
	}

	function hasVenuemaps() {
		for (let event of events) {
			if (event.venuemap) {
				return true;
			}
		}
		return false;
	}
	return (
		<div id={`quick-scan-options`} className={open ? "open" : undefined}>
			<button className="close" onClick={close}>
				<IconTimes /> <Localized id="scandrawer-close" />
			</button>
			<div className="option-container">
				<h1 className="heading">
					<Localized id="scandrawer-heading" />
				</h1>

				<div className="time-tolerance">
					<label>
						<Localized id="scandrawer-timetolerance" />
					</label>
					<small>
						<Localized id="scandrawer-timetolerance-explanation" />
					</small>

					<Select value={selectedTimeTolerance} onChange={e => setSelectedTimeTolerance(e.target.value)}>
						<option value="0">
							<Localized id="scandrawer-tolerance-0" />
						</option>
						<option value="15">
							<Localized id="scandrawer-tolerance-15" />
						</option>
						<option value="30">
							<Localized id="scandrawer-tolerance-30" />
						</option>
						<option value="60">
							<Localized id="scandrawer-tolerance-60" />
						</option>
						<option value="180">
							<Localized id="scandrawer-tolerance-180" />
						</option>
						<option value="360">
							<Localized id="scandrawer-tolerance-360" />
						</option>
						<option value="720">
							<Localized id="scandrawer-tolerance-720" />
						</option>
						<option value="1440">
							<Localized id="scandrawer-tolerance-1440" />
						</option>
					</Select>
				</div>

				<div className="expired-ticket-acceptance">
					<label>
						<Localized id="scandrawer-expired-ticket-acceptance" />
					</label>
					<div className="section-switch">
						<small>
							<Localized id="scandrawer-expired-ticket-acceptance-explanation" />
						</small>
						<ReactSwitch
							checked={selectedExpTicketAcceptance}
							onChange={checked => setSelectedExpTicketAcceptance(checked)}
						/>
					</div>
				</div>

				<div className="scan-mode">
					<label>
						<Localized id="scandrawer-state" />
					</label>
					<small>
						<Localized id="scandrawer-state-explanation" />
					</small>

					<div className={`scan-mode-button${selectedMode === "entry" ? " active" : ""}`}>
						<div onClick={() => setSelectedMode("entry")}>
							<h1>
								<Localized id="scandrawer-entry-title" />
							</h1>
							<p>
								<Localized id="scandrawer-entry-description" />{" "}
							</p>
						</div>
					</div>
					{hasVenuemaps() && (
						<div className={`scan-mode-button${selectedMode === "section" ? " active" : ""}`}>
							<div onClick={() => setSelectedMode("section")}>
								<h1>
									<Localized id="scandrawer-section-title" />
								</h1>
								<p>
									<Localized id="scandrawer-section-description" />
								</p>
							</div>

							{selectedMode === "section" && (
								<div className="section-select">
									<label>
										<Localized id="scandrawer-section-selected-section" />
									</label>
									<select
										value={selectedSection || ""}
										onChange={e => setSelectedSection(e.target.value, e)}
									>
										<option default hidden value={""}>
											<Localized id="scandrawer-section-selected-default" />
										</option>

										{events.map(
											e =>
												e.venuemap?.sections?.length > 0 && (
													<optgroup label={e.event_name} key={`event-${e.slug}`}>
														{e.venuemap.sections.map(section => (
															<option
																value={section.id}
																key={`event-${e.slug}-${section.id}`}
															>
																{section.name}
															</option>
														))}
													</optgroup>
												)
										)}
									</select>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			<button className="select-scan-type-button" disabled={buttonDisabled()} onClick={saveChanges}>
				<Localized id="scandrawer-select-button" />
			</button>
		</div>
	);
};

export default ScanOptionsDrawer;
