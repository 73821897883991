/** @format */

import { Localized } from "@fluent/react";
import { Stepper } from "@mantine/core";
import { IconQrCode, IconUserPlus, IconUserTimes } from "Components/icons/icons";
import Subheading from "Components/onboard/subheading";
import { TicketStorageContext } from "Context/ticketStorageContext";
import moment from "moment/moment";
import { useContext, useState } from "react";
import "./interruptModal.scss";
import TicketScanHistory from "Components/scanHistoryforTicket/scanHistoryForTicket";

const ScanInterruptModal = ({ scanData, manualDecline, manualAccept }) => {
	const { manualVerifyTicket } = useContext(TicketStorageContext);
	const { totalScanHistoryForTicket = [], ticketData, interruptInfo = "", method } = scanData;

	const [note, setNote] = useState("");
	let notes = [];

	for (let row of totalScanHistoryForTicket) {
		if (row?.metadata?.notes) {
			for (let key in row.metadata.notes) {
				notes.push({ scanner: [key], note: row.metadata.notes[key] });
			}
		}
	}

	async function manualAccepted(direction) {
		let metadata = scanData.metadata;
		if (note) metadata["notes"] = note;

		await manualVerifyTicket(ticketData.id, metadata, direction);

		manualAccept(scanData?.ticketData, scanData?.productData.product);
	}

	return (
		<div id="scan-interrupt-modal">
			<div className="content">
				<h1>{interruptInfo ? interruptInfo : <Localized id="interrupt-default-title" />}</h1>

				<Subheading>
					<Localized id="scanpopup-history-heading" />
				</Subheading>

				<div className="info-group">
					<TicketScanHistory history={totalScanHistoryForTicket} />
				</div>
			</div>
			<div className="actions">
				<div className="action-buttons">
					<button className="accept" onClick={() => manualAccepted("exit")}>
						<IconUserTimes size="20" />
						<Localized id="scanpopup-readout" />
					</button>
					<button
						className="decline"
						onClick={() => manualDecline(scanData?.ticketData, scanData?.productData.product)}
					>
						<Localized id="scanpopup-decline" />
					</button>
					<button className="accept" onClick={() => manualAccepted("entry")}>
						<IconUserPlus size="20" />
						<Localized id="scanpopup-readin" />
					</button>
				</div>

				<label>
					<Localized id="scanpopup-note-label" />
				</label>
				<Localized id="scanpopup-note-input" attrs={{ placeholder: true }}>
					<input value={note} onChange={e => setNote(e.target.value)} />
				</Localized>
			</div>
		</div>
	);
};

export default ScanInterruptModal;
