/** @format */

import Card from "Components/card/card";
import { IconChevronLeft, IconQrCode } from "Components/icons/icons";
import AsyncDatabaseCallHandler from "Components/wrappers/dbAsyncHandler";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { useDbAsyncStatusHandler } from "hooks/asyncHandler";
import { getEventsRecordsFromDatabase, getScannedTicketsRecordsFromDatabase } from "indexedDb/dexie";
import { useCallback, useRef } from "react";
import { useEffect, useState } from "react";

import * as Lvt from "StyledComponents/components";
import "./tickets.scss";
import { NavLink, useParams } from "react-router-dom";
import {
	scannerScannedExternalTicketsPath,
	scannerScannedPath,
	scannerScannedProductsEventPath,
	scannerScannedProductsPath,
	scannerScannedProductsProductPath,
	scannerScannedTicketPath,
} from "constants/paths";
import { Localized } from "@fluent/react";
import { Switch, useHistory } from "react-router-dom/cjs/react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { useMemo } from "react";

const DEFAULT_SHOW_AMOUNT = 50;

const Tickets = () => {
	const [tickets, setTickets] = useState([]);
	const [events, setEvents] = useState([]);

	const { apikey } = useParams();
	const { asyncState: asyncDbState, startCall: startDbCall, resolveCall: resolveDbCall } = useDbAsyncStatusHandler();

	const groupedByEventAndProduct = useMemo(() => {
		if (events.length > 0) {
			let eventArr = events.map(event => {
				const thisEventProducts = event.products.map(p => {
					let variantsWithScans = [];
					p.variants.forEach(v => {
						const scans = tickets.filter(t => t.product_id === v.id);
						if (scans.length > 0) variantsWithScans.push({ ...v, scans });
					});
					return { ...p, variants: variantsWithScans };
				});
				const productsWithScans = thisEventProducts.filter(p => p.variants.length > 0);
				const thisEventExternalTickets = tickets.filter(t => !t.product_id && t.event_slug === event.slug);
				return { ...event, products: productsWithScans, external_tickets: thisEventExternalTickets };
			});
			return eventArr.filter(e => e.products.length > 0 || e.external_tickets.length > 0);
		}
		return null;
	}, [JSON.stringify(tickets), events.length]);

	async function getTickets() {
		startDbCall();
		const { status, data } = await getScannedTicketsRecordsFromDatabase();
		if (status === DB_RESPONSE_OK) {
			setTickets(data);
		}
		resolveDbCall(status);
	}

	const getEvens = async () => {
		const { status, data } = await getEventsRecordsFromDatabase();
		setEvents(data);
	};

	useEffect(() => {
		getTickets();
		getEvens();
	}, []);

	return (
		<div id="scan-list">
			<AsyncDatabaseCallHandler {...asyncDbState}>
				<Switch>
					<Route path={scannerScannedPath(":apikey")} exact>
						<Scans tickets={tickets} />
					</Route>
					<Route path={scannerScannedProductsPath(":apikey")} exact>
						<Products groupedByEventAndProduct={groupedByEventAndProduct} />
					</Route>
					<Route path={scannerScannedProductsProductPath(":apikey", ":product_id")} exact>
						<ProductScans tickets={tickets} events={events} />
					</Route>
					<Route path={scannerScannedExternalTicketsPath(":apikey", ":event_slug")} exact>
						<ExternalScans tickets={tickets} />
					</Route>
					<Route path={scannerScannedProductsEventPath(":apikey", ":event_slug")} exact>
						<EventScans tickets={tickets} events={events} />
					</Route>
				</Switch>
			</AsyncDatabaseCallHandler>
			<Lvt.Row className="scan-list-tabs">
				<Lvt.Link
					to={scannerScannedPath(apikey)}
					isActive={(match, location) => location.pathname === scannerScannedPath(apikey)}
				>
					<Localized id="ticketsview-button-scannedtickets">Scanned tickets</Localized>
				</Lvt.Link>
				<Lvt.Link
					to={scannerScannedProductsPath(apikey)}
					exact
					isActive={(match, location) => location.pathname.startsWith(scannerScannedProductsPath(apikey))}
				>
					<Localized id="ticketsview-button-scannedproducts">Scanned products</Localized>
				</Lvt.Link>
			</Lvt.Row>
		</div>
	);
};

const Products = ({ groupedByEventAndProduct = [] }) => {
	const { apikey } = useParams();

	const noScans = groupedByEventAndProduct.length === 0;

	return (
		<div className="products">
			{noScans ? (
				<div className="grouped-noscans">No scans</div>
			) : (
				groupedByEventAndProduct.map(event => {
					const { event_name = "", products = [], external_tickets = [] } = event;
					const totalScans =
						products.reduce(
							(total, currP) =>
								(total += currP.variants.reduce(
									(totalVarScans, currV) => (totalVarScans += currV.scans.length),
									0
								)),
							0
						) + external_tickets.length;
					return (
						<div className="grouped-event">
							<h2 className="grouped-event-name">{event_name}</h2>
							<div className="grouped-event-list">
								{products.map(product =>
									product.variants.map(variant => {
										const productName = `${product.name} / ${variant.name}`;
										const scanAmount = variant.scans.length;
										return (
											<NavLink to={scannerScannedProductsProductPath(apikey, variant.id)}>
												<Card contentWrap={false}>
													<div className="product">
														<div className="info">
															<p className="product-name">{productName}</p>
														</div>
														<h1 className="scan-amount">{scanAmount}</h1>
													</div>
												</Card>
											</NavLink>
										);
									})
								)}

								{external_tickets.length > 0 && (
									<NavLink to={scannerScannedExternalTicketsPath(apikey, event.slug)}>
										<Card contentWrap={false}>
											<div className="product">
												<div className="info">
													<p className="product-name total">
														<Localized id="ticketsview-external-label">
															External tickets
														</Localized>
													</p>
												</div>
												<h1 className="scan-amount">{external_tickets.length}</h1>
											</div>
										</Card>
									</NavLink>
								)}

								<NavLink to={scannerScannedProductsEventPath(apikey, event.slug)}>
									<Card contentWrap={false}>
										<div className="product">
											<div className="info">
												<p className="product-name total">
													<Localized id="ticketsview-total-label">Total</Localized>
												</p>
											</div>
											<h1 className="scan-amount">{totalScans}</h1>
										</div>
									</Card>
								</NavLink>
							</div>
						</div>
					);
				})
			)}
		</div>
	);
};

const Heading = ({ title }) => {
	const history = useHistory();
	return (
		<div className="scans-heading">
			<button onClick={() => history.goBack()}>
				<IconChevronLeft /> <Localized id="ticketsview-back-button">Back</Localized>
			</button>
			<h1>{title}</h1>
		</div>
	);
};

const ProductScans = ({ tickets = [], events = [] }) => {
	const { product_id } = useParams();
	const productId = parseInt(product_id);
	const ticketData = tickets.filter(t => t.product_id === productId);

	const event_slug = ticketData?.[0].event_slug;
	const findEvent = events.find(e => e.slug === event_slug);
	const findProduct = findEvent.products.find(p => p.variants.map(v => v.id).includes(productId));
	const findVariant = findProduct.variants.find(v => v.id === productId);
	const title = findProduct && findVariant ? `${findProduct?.name} / ${findVariant?.name}` : "";
	return (
		<>
			<Heading title={title} />
			<Scans tickets={ticketData} />
		</>
	);
};

const ExternalScans = ({ tickets = [] }) => {
	const { event_slug } = useParams();
	const ticketData = tickets.filter(t => !t.product_id && t.event_slug === event_slug);
	const title = <Localized id="ticketsview-external-label">External tickets</Localized>;
	return (
		<>
			<Heading title={title} />
			<Scans tickets={ticketData} />
		</>
	);
};

const EventScans = ({ tickets = [], events = [] }) => {
	const { event_slug } = useParams();
	const ticketData = tickets.filter(t => t.event_slug === event_slug);
	const title = events.find(e => e.slug === event_slug)?.event_name;
	return (
		<>
			<Heading title={title} />
			<Scans tickets={ticketData} />
		</>
	);
};

const Scans = ({ tickets }) => {
	const [filteredTickets, setFilteredTickets] = useState([]);
	const [showAmount, setShowAmount] = useState(DEFAULT_SHOW_AMOUNT);
	const [filter, setFilter] = useState("");
	const scrollRef = useRef();
	const ticketList = filter
		? filteredTickets.filter(t => t.ticket_code.includes(filter)).slice(0, showAmount)
		: tickets.slice(0, showAmount);
	useEffect(() => {
		if (filter) {
			setFilteredTickets(tickets.filter(t => t.ticket_code.includes(filter)));
		}
		if (showAmount !== DEFAULT_SHOW_AMOUNT) {
			setShowAmount(DEFAULT_SHOW_AMOUNT);
		}
	}, [filter]);

	const onScroll = useCallback(() => {
		const hasScrollbar = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
		const scrollThreshold =
			scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight;
		if (scrollThreshold === 0 && hasScrollbar) {
			setShowAmount(showAmount + DEFAULT_SHOW_AMOUNT);
		}
	}, [showAmount]);
	return (
		<>
			<div className="scans" onScroll={onScroll} ref={scrollRef}>
				{ticketList.length > 0 ? (
					<div className="list">
						{ticketList.map(t => (
							<Ticket {...t} key={`ticket-${t.ticket_code}`} />
						))}
					</div>
				) : (
					<NoTickets filter={filter} />
				)}
			</div>
			<div className="scan-search">
				<label>
					<Localized
						id="ticketsview-find-label"
						vars={{ amount: filter ? filteredTickets.length : tickets.length }}
					/>
				</label>

				<Localized id="ticketsview-find-input" attrs={{ placeholder: true }}>
					<input
						className="ticket-search-input"
						value={filter}
						onChange={e => setFilter(e.target.value.toUpperCase())}
					/>
				</Localized>
			</div>
		</>
	);
};

const NoTickets = ({ filter }) => {
	return (
		<div className="no-tickets">
			{filter ? (
				<>
					<p>
						<Localized id="ticketsview-no-tickets-filter" />
					</p>
					<b>{filter}</b>
				</>
			) : (
				<p>
					<Localized id="ticketsview-no-tickets" />
				</p>
			)}
		</div>
	);
};

const Ticket = props => {
	const { apikey } = useParams();
	const { title = "", ticket_code = "", scanned = "", event_slug, idx } = props;
	return (
		<NavLink to={scannerScannedTicketPath(apikey, ticket_code)}>
			<Card contentWrap={false}>
				<div className="ticket">
					<IconQrCode size="80" className="icon" />
					<div className="info">
						<p className="ticket-code">{ticket_code}</p>
						<p className="product-name">{title}</p>
					</div>
					<Lvt.Text set="h4" className="event">
						{event_slug}
					</Lvt.Text>
				</div>
			</Card>
		</NavLink>
	);
};

export default Tickets;
