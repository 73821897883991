import { useCallback, useEffect, useRef, useState } from "react";
import { hapticFeedback } from "util/vibrate";


const LaserReader = ({ onUpdate, blocked }) => {
    const zebraReadStreamRef = useRef("");
    const [zebraScanCode, setZebraScanCode] = useState('');

    const blockedRef = useRef();
    blockedRef.current = blocked;


    useEffect(() => {
        if (!blocked) {
            blockedRef.current = false;
        }
    }, [blocked])


    const laserHandler = useCallback((e) => {
        zebraReadStreamRef.current += e.key;

        const matchesPrefix = zebraReadStreamRef.current.includes('scan-');
        const matchesSuffix = zebraReadStreamRef.current.includes('-scan');

        if (!blockedRef.current && matchesPrefix && matchesSuffix) {
            const code = zebraReadStreamRef.current.substring(5, zebraReadStreamRef.current.length - 5);
            setZebraScanCode(code)
            hapticFeedback([100]);
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keypress', laserHandler)
        return () => {
            document.removeEventListener('keypress', laserHandler)
        }
    }, [])

    useEffect(() => {
        if (zebraScanCode) {
            blockedRef.current = true;
            onUpdate(null, { text: zebraScanCode });

            setZebraScanCode("");
            zebraReadStreamRef.current = "";
        }
    }, [zebraScanCode])


    return null;

}


export default LaserReader;