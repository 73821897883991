import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app.jsx";
import AppLocalizationProvider from "util/localizationProvider";

import * as Sentry from "@sentry/browser";

import "scss/main.scss";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://da7e1164a26e780312fe60399c6e2436@o302617.ingest.sentry.io/4506076797534208",
    tracesSampleRate: 0.05,
    environment: process.env.REACT_APP_SENTRY_ENV || "development",
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

//const oppositeOrientation = screen.orientation.type.startsWith("portrait") ? "landscape" : "portrait";
//screen.orientation.lock("portrait")
const root = createRoot(document.getElementById("root"));
root.render(
  <AppLocalizationProvider>
    <App />
  </AppLocalizationProvider>
);

serviceWorkerRegistration.register();
