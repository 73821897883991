const LivetoLoader = (props) => {
    const color = props.color ? props.color : "#d62b1e";
    const width = props.size ? props.size : 200;
    const height = parseInt((width / 59) * 25);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 59.170112 25.013427" version="1.1" id="svg8">
            <defs id="defs2" />
            <g id="layer1" transform="translate(312.85437,517.33411)">
                <filter id="blurMe">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                </filter>
                <path
                    mask="url(#hole)"
                    style={{ fill: color, fillOpacity: "1" }}
                    d="m -310.06006,-498.58309 c -1.59114,-3.62807 -2.79256,-6.66306 -2.79256,-6.66306 0,0 3.43418,-1.55602 7.50877,-3.37647 4.07458,-1.82044 9.94833,-4.45484 13.05277,-5.85423 6.53353,-2.94511 5.91859,-3.06875 7.9422,1.59692 0.81556,1.88036 1.61456,3.54716 1.61456,3.54716 0,0 -1.12317,0.49003 -2.06703,0.88408 -0.61417,0.25642 -1.22128,0.49085 -1.22128,0.49085 l -2.04079,-4.31668 -20.21821,8.90536 3.13226,6.45109 c 0,0 10.78779,-4.66876 23.34823,-10.32004 12.56044,-5.65129 23.04902,-10.19377 23.30795,-10.0944 0.19784,-0.003 1.74099,3.02013 3.29345,6.4906 1.17751,2.6323 1.81002,4.59252 1.28029,5.90836 -0.74008,1.83839 -3.79996,2.82455 -11.26376,6.20974 -7.04336,3.1945 -12.3729,5.6158 -13.11805,5.71339 -0.4309,0.038 -0.8551,-0.32059 -1.31618,-1.11797 -0.19275,-0.33333 -2.91645,-6.17712 -2.91645,-6.17712 l 2.98778,-1.39366 2.17013,4.16465 19.8209,-8.90188 -2.65072,-6.41118 c 0,0 -12.07808,5.36747 -27.32278,12.25941 -4.94771,2.23679 -9.49742,4.27899 -10.11047,4.53821 -0.61305,0.25922 -2.65483,1.19097 -4.53728,2.07056 -5.38449,2.51593 -4.54677,3.00512 -7.88373,-4.60369 z"
                    id="path56"
                />
                <mask id="hole">
                    <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="m -283.30226,-508.76903 -3.2976,-5.76732 -22.12238,10.23045 3.64175,9.71793 46.54237,-20.49431 6.17857,9.58619 -22.74206,10.21346 -3.04954,-4.75108 -0.007,-1.5145"
                        id="path"
                        style={{
                            fill: "none",
                            stroke: "white",
                            strokeWidth: "9px",
                            strokeLinejoin: "round",
                            strokeOpacity: "1",
                            strokeDasharray: "35",
                            strokeDashoffset: "10",
                        }}>
                        <animate
                            xmlns="http://www.w3.org/2000/svg"
                            id="firstanim"
                            attributeType="CSS"
                            attributeName="stroke-dashoffset"
                            from="0"
                            to="140"
                            dur="3s"
                            repeatCount="indefinite"
                        />
                    </path>
                </mask>
            </g>
        </svg>
    );
};

export default LivetoLoader;
