import { ConfigurationsContext } from 'Context/configurations';
import { TicketStorageContext } from 'Context/ticketStorageContext';
import { useLayoutEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import * as Lvt from 'StyledComponents/components';

import { PieChart, Pie } from 'recharts';

import './home.scss';
import Card from 'Components/card/card';
import { Localized } from '@fluent/react';

const Home = props => {

    const { scannerConfiguration, scanSettings } = useContext(ConfigurationsContext);
    const { ticketsAmount, offlineTicketsAmount = 0, scansAmount = 0 } = useContext(TicketStorageContext);

    const { title } = scannerConfiguration
    const { device_name } = scanSettings;

    return <div id="home">

        <Card bgColor="teal" title={<Localized id="ac-home-visitors-title" />}>
            <ChartData />
        </Card>

        <Card bgColor='white' title={<Localized id="ac-home-info" />}>
            <Lvt.Col className="in-numbers">
                <Lvt.Col justify="between" className="info-row">
                    <p className='label'><Localized id="ac-home-allscans" /></p>
                    <p className='value'>{scansAmount} <Localized id="pcs"/></p>

                </Lvt.Col>
                <Lvt.Col justify="between" className="info-row">
                    <p className='label'><Localized id="ac-home-offlinetickets" /></p>
                    <p className='value'>{offlineTicketsAmount} <Localized id="pcs"/></p>
                </Lvt.Col>

                <hr />

                <Lvt.Col justify="between" className="info-row">
                    <p className='label' ><Localized id="ac-home-setting-name"/></p>
                    <p className='value'>{title}</p>
                </Lvt.Col>
                <Lvt.Col justify="between" className="info-row">
                    <p className='label' ><Localized id="ac-home-device-name"/></p>
                    <p className='value'>{device_name}</p>
                </Lvt.Col>
            </Lvt.Col>

        </Card>

    </div >
}

const ChartData = () => {
    const chartContainerRef = useRef();

    const { lastSync, uniqueScansAmount = 0, ticketsAmount = 0 } = useContext(TicketStorageContext);

    const [chartWidth, setChartWidth] = useState(null);

    useLayoutEffect(() => {
        setChartWidth(chartContainerRef.current.offsetWidth);
    }, [])


    const chartSpaceWithPadding = chartWidth - 20 > 130 ? 130 : chartWidth;

    const percentage = uniqueScansAmount / ticketsAmount * 100 || 0;

    return <div className='chart'>
        <div className='chart-content'>

            <div className='chart-container' ref={chartContainerRef}>
                <div className='percent'>{percentage.toFixed(0)}%</div>
                <PieChart width={chartSpaceWithPadding} height={chartSpaceWithPadding}>
                    <Pie
                        cx="50%"
                        cy="50%"
                        outerRadius={chartSpaceWithPadding / 2}
                        innerRadius={chartSpaceWithPadding / 2 - 10}
                        data={[
                            { name: 'scanned', value: uniqueScansAmount, fill: '#d62b1e' },
                            { name: 'not scanned', value: ticketsAmount - uniqueScansAmount, fill: '#fff' }
                        ]}
                    />
                </PieChart>
            </div>


            <div className='chart-data'>
                <div className='chart-data-content'>
                    <Lvt.Col justify="between">
                        <p className='chart-p'><Localized id="ac-home-visitors-registered" /></p>
                        <p className='chart-p b'>{ticketsAmount}</p>
                    </Lvt.Col>
                    <Lvt.Col justify="between">
                        <p className='chart-p'><Localized id="ac-home-visitors-visited" /></p>
                        <p className='chart-p b'>{uniqueScansAmount}</p>
                    </Lvt.Col>
                </div>

            </div>
        </div>
    </div>

}

export default Home;