import { Localized } from "@fluent/react";
import { ConfigurationsContext } from "Context/configurations";
import { useContext } from "react";

import './scanModeHumanReadable.scss';

const ScanModeHumanReadable = () => {
    const { scanMode } = useContext(ConfigurationsContext)
    const { mode, sectionName } = scanMode;

    return <div id='scan-mode-human-readable'>
        <h2>
            {mode === "entry" ?
                <Localized id="scanview-currentmode-entry" />
                :
                <Localized id="scanview-currentmode-section" vars={{ section: sectionName }} />
            }
        </h2>
    </div>
}

export default ScanModeHumanReadable;