

export const NOTFOUND = 0;
export const FOUND = 1;
export const ALREADY_SCANNED = 2;
export const NOT_VALID_YET = 3;
export const NOT_VALID_ANYMORE = 4;


export const FLAG_RED = 0;
export const FLAG_YELLOW = 1;
export const FLAG_GREEN = 2;