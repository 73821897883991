/** @format */

import { ConfigurationsContext } from "Context/configurations";
import * as Lvt from "StyledComponents/components";
import { scannerEventDetailsPath, scannerEventsPath } from "constants/paths";
import { useContext } from "react";
import { Route, useParams } from "react-router";
import ScannerEventDetails from "./eventDetail";
import "./events.scss";
import { useLocalization } from "@fluent/react";

const ScannerEventList = props => {
	const { events = [] } = useContext(ConfigurationsContext);

	return (
		<div id="scanner-events">
			{events.map(e => (
				<Event {...e} key={`event-${e.slug}`} />
			))}
		</div>
	);
};


function getAmountLocale(item, amount) {
	const { l10n } = useLocalization();
	let localeString;
	switch (amount) {
		case 0:
			localeString = l10n.getString(`events-${item}-0`);
			break;
		case 1:
			localeString = `${amount} ${l10n.getString(`events-${item}-1`)}`;
			break;
		default:
			localeString = `${amount} ${l10n.getString(`events-${item}-2`)}`;
			break;
	}
	return localeString;
}

const Event = props => {
	const { apikey } = useParams();
	const { event_name, slug, access_controls, products } = props;

	const productString = getAmountLocale("products", products.length);
	const accessControlString = getAmountLocale("accesscontrols", access_controls.length);
	const mergedString = `${productString}, ${accessControlString}`;

	return (
		<Lvt.Link to={`${scannerEventsPath(apikey)}/${slug}`} className="event-row">
			<div className="event-info">
				<span className="event-name">{event_name}</span>
				<span className="event-details">{mergedString}</span>
			</div>
		</Lvt.Link>
	);
};

export default ScannerEventList;
