import LivetoLoader from 'Components/loader/livetoLoader';
import './loadPage.scss';


const LoadPage = ({ text }) => {
    return <div id="load-page">
        <LivetoLoader color="#fff" size="100" />
        <p>{text}</p>
    </div>
}

export default LoadPage;