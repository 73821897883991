const encodeQueryParam = (key, value) => {
    const prefix = encodeURIComponent(key) + "=";
    let components = [];
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return undefined;
        }
        for (let i = 0; i < value.length; ++i) {
            components.push(prefix + encodeURIComponent(value[i]));
        }
    } else {
        if (!value) {
            return undefined;
        }
        components.push(prefix + encodeURIComponent(value));
    }

    return components.join("&");
};

export const encodeQueryParams = (params) => {
    const query = Object.keys(params)
        .map((k) => encodeQueryParam(k, params[k]))
        .filter((x) => x !== undefined)
        .join("&");
    if (query !== "") {
        return "?" + query;
    } else {
        return "";
    }
};
