import { useLocalStorage } from "hooks/localStorage";
import moment from "moment/moment";

const { createContext } = require("react");



export const LoggingContext = createContext([]);

const LoggingContextProvider = ({ children }) => {

    const [log, setLog] = useLocalStorage('device-log', []);

    const addLogEntry = (entry) => {
        const timeStamp = moment().format('YYYY-MM-DDTHH:mm:ss');
        const newLog = [...log];
        newLog.unshift({ event: entry.event, data: entry.data, timeStamp: timeStamp });
        setLog(newLog)
    }

    const addMultipleLogEntries = (entries) => {
        setLog([...entries, ...log])
    }

    const ctx = {
        log, addLogEntry, addMultipleLogEntries
    }

    return <LoggingContext.Provider value={ctx}>
        {children}
    </LoggingContext.Provider>
}

export default LoggingContextProvider;