import { TicketStorageContext } from 'Context/ticketStorageContext';
import moment from 'moment/moment';
import { useContext } from 'react';
import './header.scss';
import { Localized } from '@fluent/react';

const ScannerHeader = props => {
    const { lastSync } = useContext(TicketStorageContext);
    return <>
        <div className="scanner-header">
            <p className="scanner-slogan">{'SKANNERI'}</p>
            <small>
                <Localized id="header-last-sync" vars={{ lastSync: moment(lastSync).format('DD.MM.YYYY HH:mm:ss') }} />
            </small>
        </div>

    </>
}

export default ScannerHeader;