import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import Paragraph from "Components/onboard/paragraph";
import { useDbAsyncStatusHandler } from "hooks/asyncHandler";
import { useHistory } from "react-router";

import './resetSettings.scss';
import SettingsWrapper from "Components/settings/wrapper/wrapper";
import { Localized } from "@fluent/react";
import { removeAllDataFromDatabase } from "indexedDb/dexie";

const ResetSettings = () => {

    const history = useHistory();

    const { asyncState, startCall, resolveCall } = useDbAsyncStatusHandler();

    async function removeConfiguration() {
        startCall();
        localStorage.clear();
        const { status, data } = await removeAllDataFromDatabase();
        resolveCall(status);
        window.location.href = '/';
    }


    return <SettingsWrapper id="reset-settings">

        <div className="text-content">
            <OnboardHeading>
                <Localized id="reset-app-title" />
            </OnboardHeading>

            <Paragraph>
                <Localized id="reset-app-desc-1" />
            </Paragraph>

            <Paragraph>
                <Localized id="reset-app-desc-2" />

            </Paragraph>
            <Paragraph>
                <Localized id="reset-app-areyousure" />
            </Paragraph>
        </div>


        <ForwardButton type="button" onClick={removeConfiguration}>Poista</ForwardButton>
        <Paragraph>
            {asyncState.loading ? 'Poistetaan...' : 'Poista aiempi asetus ja palaa alkuun'}
        </Paragraph>
    </SettingsWrapper>
}

export default ResetSettings;