import Scanner from "Components/qr-reader/scanner";
import { useState } from "react";
import OnboardWrapper from "Components/onboard/onboardWrapper"
import OnboardHeading from "Components/onboard/heading";
import Paragraph from "Components/onboard/paragraph";
import { useHistory } from "react-router";
import { appOnboardLoadConfigurationPath } from "constants/paths";
import { Localized } from "@fluent/react";
import LaserReader from "Components/qr-reader/laser";

const AppReadConfig = () => {
    const history = useHistory();

    const onRead = async (err, result) => {
        if (result) {
            const { text = "" } = result;
            if (!err && text) {
                history.push(appOnboardLoadConfigurationPath(text));
            }
        }

    }

    return <OnboardWrapper>
        <LaserReader onUpdate={onRead} blocked={false} />
        <Scanner onUpdate={onRead} delay={500} width={'100%'} height={'100%'} />
    </OnboardWrapper>
}



export default AppReadConfig;