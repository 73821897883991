
import './startOnboard.scss';
import { useParams } from 'react-router';
import logo from 'images/liveto-logo.png';
import { useContext, useEffect, useState } from "react";
import OnboardWrapper from 'Components/onboard/onboardWrapper';
import { Localized } from '@fluent/react';
import { ConfigurationsContext } from 'Context/configurations';
import { useHistory } from 'react-router';
import { appOnboardSetupConfigPath } from 'constants/paths';
import ForwardButton from 'Components/onboard/forwardButton';
import { OnboardNextPhaseTip } from 'Components/onboard/paragraph';
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect';
import { IconAddressCard, IconIosMenu } from 'Components/icons/icons';

// Somewhat of an ugly hack but beforeinstallprompt might fire at any time
// including before the first processing of any react components/hooks, in
// which case we need to capture it outside react and use as initial prompt
let beforeInstallPrompt = null;
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    beforeInstallPrompt = e;
})

const StartOnboard = () => {
    const { apikey = "" } = useParams();
    const history = useHistory();
    const { modifyUserSetting, userSettings } = useContext(ConfigurationsContext);
    const { language } = userSettings;

    const [promptInstall, setPromptInstall] = useState(beforeInstallPrompt);
    beforeInstallPrompt = null; // Ensure we don't end up reusing the globally captured reference multiple times

    const [displayMode, setDisplayMode] = useState(getPWADisplayMode());

    function getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'twa';
        } else if (navigator.standalone || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

    useEffect(() => {
        const installHandler = e => {
            e.preventDefault();
            setPromptInstall(e);
        };

        const handleTransition = () => {
            setDisplayMode('standalone')
        }

        window.addEventListener("beforeinstallprompt", installHandler);
        window.addEventListener('appinstalled', handleTransition);
        return () => {
            window.removeEventListener("transitionend", installHandler);
            window.removeEventListener("appinstalled", handleTransition);
        }


    }, []);


    function handleLanguage(value) {
        modifyUserSetting('language', value);
    }
    const requestInstall = async (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
        const { outcome } = await promptInstall.userChoice;

        if (outcome === 'accepted') {
            setDisplayMode('standalone');
        }
        if (outcome === 'dismissed') {
        }
    };

    return <OnboardWrapper id="start-onboard" showBackButton={false}>
        <div className='heading-container'>
            <img src={logo} className="logo" />
            <h2 className='heading'><Localized id="root-appname" /></h2>



        </div>

        <div className='language-select'>
            <label><Localized id="root-select-language" /></label>
            <select className='language-select' value={language} onChange={e => handleLanguage(e.target.value)}>
                <option value="en"><Localized id="root-select-language-en" /></option>
                <option value="fi"><Localized id="root-select-language-fi" /></option>
            </select>
        </div>

        <div className='buttons-container'>
            {displayMode === "browser" && <>
                {(isSafari || isMobileSafari || !promptInstall) ?
                    null
                    :
                    <button className='install-app' onClick={e => requestInstall(e)}>
                        <Localized id="root-install-application" />
                    </button>
                }



                <button className='continue-browser' onClick={() => history.push(appOnboardSetupConfigPath(apikey))}>
                    <Localized id="root-continue-in-browser" />
                </button>
            </>}

            {displayMode !== 'browser' && <>
                <ForwardButton type="button" onClick={() => history.push(appOnboardSetupConfigPath(apikey))}>
                    <Localized id="onboard-continue-button" />
                </ForwardButton>
                <OnboardNextPhaseTip>

                </OnboardNextPhaseTip>
            </>}

        </div>

    </OnboardWrapper>
}

export default StartOnboard;