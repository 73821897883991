/** @format */

const { createContext, useContext, useState } = require("react");
import { Notification } from "@mantine/core";
import { IconCheck, IconTimes } from "Components/icons/icons";
import "./notificationContext.scss";

const NotificationContext = createContext({});

export const NotificationContextProvider = ({ children }) => {
	const [notification, setNotification] = useState({
		show: false,
		title: "",
		color: "",
		icon: "",
		children: "",
	});

	const handleCloseNotification = () => {
		setNotification(prev => ({ ...prev, show: false }));
	};
	const handleErrorNotification = data => {
		setNotification({
			...data,
			color: "red",
			icon: <IconTimes size="16" />,
			show: true,
			className: "scan-notification error",
		});
	};
	const handleSuccessNotification = data => {
		setNotification({
			...data,
			color: "green",
			icon: <IconCheck size="16" />,
			show: true,
			className: "scan-notification success",
		});
		setTimeout(() => {
			handleCloseNotification();
		}, 3000);
	};
	const value = { handleErrorNotification, handleSuccessNotification };
	return (
		<NotificationContext.Provider value={value}>
			{notification.show && (
				<Notification onClose={handleCloseNotification} className="scan-notification" {...notification} />
			)}
			{children}
		</NotificationContext.Provider>
	);
};
export const useNotificationContext = () => useContext(NotificationContext);
