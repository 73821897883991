/** @format */

import AsyncDatabaseCallHandler from "Components/wrappers/dbAsyncHandler";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { useDbAsyncStatusHandler } from "hooks/asyncHandler";
import { getTicketData } from "indexedDb/dexie";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import "./ticketsSingleTicket.scss";
import { IconChevronLeft } from "Components/icons/icons";
import { Stepper } from "@mantine/core";
import TicketScanHistory from "Components/scanHistoryforTicket/scanHistoryForTicket";
import { Localized } from "@fluent/react";
import moment from "moment";

const TicketsSingleTicket = () => {
	const { ticket_code } = useParams();
	const { asyncState: asyncDbState, startCall: startDbCall, resolveCall: resolveDbCall } = useDbAsyncStatusHandler();

	const [ticketData, setTicketData] = useState(null);

	async function initTicketData() {
		startDbCall();
		const ticketResponse = await getTicketData(ticket_code);

		if (ticketResponse.status === DB_RESPONSE_OK) {
			setTicketData(ticketResponse.data);
		}

		resolveDbCall(ticketResponse.status);
	}

	useEffect(() => {
		initTicketData();
	}, []);

	return (
		<div id="ticket-info">
			<TicketHeading />

			<AsyncDatabaseCallHandler errorMessage={"Lippua ei löydy"} {...asyncDbState}>
				<div className="content">
					<ProductInfo productData={ticketData?.productData || null} />
					<TicketHistory history={ticketData?.history || null} />
				</div>
			</AsyncDatabaseCallHandler>
		</div>
	);
};

const TicketHeading = () => {
	const history = useHistory();
	const { ticket_code } = useParams();

	return (
		<div className="heading">
			<button onClick={() => history.goBack()}>
				<IconChevronLeft />
				<Localized id="singleticketview-back" />
			</button>

			<h1>{ticket_code}</h1>
		</div>
	);
};

const ProductInfo = ({ productData }) => {
	return (
		<div className="ticket-data product-info">
			<h2>
				<Localized id="singleticketview-productinfo" />
			</h2>
			{!productData ? (
				<Localized id="singleticketview-no-product-info" />
			) : (
				<>
					<div className="row-data">
						<p>
							<Localized id="singleticketview-productcategory" />
						</p>
						<b>{productData.product.name || <Localized id="singleticketview-no-product-info" />}</b>
					</div>

					<div className="row-data">
						<p>
							<Localized id="singleticketview-variant" />
						</p>
						<b>{productData.variant.name || <Localized id="singleticketview-no-product-info" />}</b>
					</div>

					<div className="row-data">
						<p>
							<Localized id="singleticketview-valid_from" />
						</p>
						<b>
							{productData.product.valid_from ? (
								moment(productData.product.valid_from).format("DD.MM.YYYY HH:mm")
							) : (
								<Localized id="singleticketview-no-product-info" />
							)}
						</b>
					</div>

					<div className="row-data">
						<p>
							<Localized id="singleticketview-valid_until" />
						</p>
						<b>
							{productData.product.valid_until ? (
								moment(productData.product.valid_until).format("DD.MM.YYYY HH:mm")
							) : (
								<Localized id="singleticketview-no-product-info" />
							)}
						</b>
					</div>
				</>
			)}
		</div>
	);
};

const TicketHistory = ({ history = [] }) => {
	return (
		<div className="ticket-data ticket-history">
			<h2>
				<Localized id="singleticketview-history" />
			</h2>
			<TicketScanHistory history={history} />
		</div>
	);
};

export default TicketsSingleTicket;
