/** @format */

import { Localized } from "@fluent/react";
import { Stepper } from "@mantine/core";
import { IconQrCode, IconUserPlus, IconUserTimes } from "Components/icons/icons";
import moment from "moment/moment";

import "./scanHistoryForTicket.scss";
import { useEffect, useState } from "react";

const DEFAULT_SHOW_AMOUNT = 10;

const TicketScanHistory = ({ history = [] }) => {
	const [ticketHistory, setTicketHistory] = useState([]);

	useEffect(() => {
		setTicketHistory(
			history.sort((a, b) => {
				return new Date(b.scanned) - new Date(a.scanned);
			})
		);
	}, [history]);

	const iconStyles = { border: "none" };
	const lineStyles = { borderLeft: "solid 2px #555" };

	const stepStyles = { width: "100%" };

	const [showAmount, setShowAmount] = useState(DEFAULT_SHOW_AMOUNT);

	return ticketHistory.length > 0 ? (
		<div className="scan-history-container">
			<Stepper
				orientation="vertical"
				size="md"
				color="#d62b1e"
				styles={{ stepIcon: iconStyles, verticalSeparator: lineStyles, step: stepStyles, stepBody: stepStyles }}
			>
				{ticketHistory.slice(0, showAmount).map((scan, idx) => {
					const icon = <IconMap type={scan.scan_type} direction={scan?.metadata?.direction || "entry"} />;

					let label = <Localized id={`scanhistory-scantype-${scan.scan_type}`} />;
					if (scan.scan_type === "section") {
						label = (
							<Localized
								id={`scanhistory-scantype-section-${scan.metadata.direction}`}
								vars={{ section: scan?.metadata?.sectionName || "" }}
							/>
						);
					}

					//const label = scan.scan_type === "section" ? `${scan.metadata.sectionName} - ${scan.metadata.direction}` : <Localized id={`scanhistory-scantype-${scan.scan_type}`} />

					return (
						<Stepper.Step
							icon={icon}
							label={label}
							key={`scan-${idx}`}
							description={
								<div className="step-description">
									<div className="step-row">
										<p>
											<Localized id="scanhistory-scanner" />
										</p>
										<b className="person">{scan.device_label}</b>
									</div>

									{scan.metadata?.expired && (
										<div className="step-row">
											<p>
												<Localized id="scanhistory-info" />
											</p>
											<b className="person"><Localized id="scanhistory-expired" /></b>
										</div>
									)}

									<div className="step-row">
										<p>
											<Localized id="scanhistory-timestamp" />
										</p>
										<p className="timestamp">
											{moment(scan.scanned).format("DD.MM.YYYY HH:mm:ss")}
										</p>
									</div>

									{scan?.metadata?.notes && <div className="step-notes">{scan.metadata.notes}</div>}
								</div>
							}
						/>
					);
				})}
			</Stepper>
			{showAmount < history.length && (
				<button className="show-more-history" onClick={() => setShowAmount(showAmount + DEFAULT_SHOW_AMOUNT)}>
					<Localized id="scanhistory-showmore" />
				</button>
			)}
		</div>
	) : (
		<p>
			<Localized id="scanhistory-nohistory" />
		</p>
	);
};

const IconMap = ({ type, direction }) => {
	switch (type) {
		case "entry":
			return (
				<div className="step-icon entry">
					<IconUserPlus style={{ color: "#fff" }} size="25" />
				</div>
			);

		case "exit":
			return (
				<div className="step-icon exit">
					<IconUserTimes style={{ color: "#fff" }} size="25" />
				</div>
			);

		default:
			return (
				<div className={`step-icon section ${direction}`}>
					<IconQrCode size="24" style={{ color: direction === "entry" ? "green" : "red" }} />
				</div>
			);
	}
};

export default TicketScanHistory;
