import { Localized } from '@fluent/react';
import { IconCalendar, IconHome, IconList, IconQrCode, IconSettings, IconTicket } from 'Components/icons/icons';
import * as paths from 'constants/paths';
import { useParams } from 'react-router';
import * as Lvt from 'StyledComponents/components';
import { hapticFeedback } from 'util/vibrate';
import './navigation.scss';

const ScannerNavigation = props => {
    const { apikey } = useParams();

    const onClick = () => {
        hapticFeedback([5])
    }
    return <div id="scanner-navigation">
        <Lvt.Link to={paths.scannerPath(apikey)} exact className="navigation-link" onClick={onClick}>
            <IconHome size="25" />
            <span className="text"><Localized id="nav-home"/></span>
        </Lvt.Link>
        <Lvt.Link to={paths.scannerScannedPath(apikey)} className="navigation-link" onClick={onClick}>
            <IconTicket size="25" />
            <span className="text"><Localized id="nav-tickets"/></span>
        </Lvt.Link>

        <div className='scan-button-wrapper'>
            <Lvt.Link to={paths.scannerScanPath(apikey)} className="navigation-link scan" onClick={onClick}>
                <IconQrCode size="40" />
                <span className='text'><Localized id="nav-scan"/></span>
            </Lvt.Link>
        </div>


        <Lvt.Link to={paths.scannerEventsPath(apikey)} className="navigation-link" onClick={onClick}>
            <IconCalendar size="25" />
            <span className="text"><Localized id="nav-events"/></span>

        </Lvt.Link>

        <Lvt.Link to={paths.settingsPath(apikey)} className="navigation-link" onClick={onClick}>
            <IconSettings size="25" />
            <span className="text"><Localized id="nav-settings"/></span>
        </Lvt.Link>

    </div>
}

export default ScannerNavigation;