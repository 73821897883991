/** @format */

import "./linkButtonWithDescriptionAndIcon.scss";

const ButtonWithDescAndIcon = ({ title, desc, icon, disabled, disabledInfo = "", ...rest }) => {
	return (
		<button
			className={`button-with-description-and-icon  ${disabled ? "disabled" : ""}`}
			disabled={disabled}
			{...rest}
		>
			<div className="icon-container">{icon}</div>
			<div className="texts">
				<b>{title}</b>
				<p>{desc}</p>
				{disabled && <p className="info">{disabledInfo}</p>}
			</div>
		</button>
	);
};
export default ButtonWithDescAndIcon;
