import { Localized } from "@fluent/react";
import LoadPage from "Components/loadPage/loadPage";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { appStartOnboard, boothPath, expiredPath, scannerPath } from "constants/paths";
import { getBoothDataFromDatabase, getScannerConfigurationFromDatabase } from "indexedDb/dexie";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";


const LandingPage = () => {
    const history = useHistory();
    const { apikey: urlApikey = "" } = useParams(); // For directly setting the configuration


    async function checkConfiguration() {
        // Get first record from indexedDb configuration table. 
        //If such exists, either redirect user to expired page if the config has expired, or take the user to the app
        const { status, data = null } = await getScannerConfigurationFromDatabase();
        // Old configuration found, handle scenarios based on the configuration statistics.
        if (status === DB_RESPONSE_OK && data) {

            const { expiration_time, apikey } = data;
            const { status: boothStatus, data: boothData } = await getBoothDataFromDatabase(apikey);
            const { booth_mode_only } = boothData;

            const expired = moment().isAfter(moment(expiration_time));

            // New apikey on the url, and the old one has expired, no problem
            if (urlApikey && expired) {
                history.push(appStartOnboard(urlApikey));
                return;

            }

            if (expired) {
                history.push(expiredPath);
                return;

            }
            if (booth_mode_only) {
                history.push(boothPath(apikey));
                return;
            }

            history.push(scannerPath(apikey))
            return;


        } else {
            history.push(appStartOnboard(urlApikey));
            return;

        }
    }

    useEffect(() => {
        checkConfiguration()
    }, [])
    return <LoadPage text={<Localized id="app-loading" />} />
}

export default LandingPage;