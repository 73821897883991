/** @format */

import { Localized } from "@fluent/react";
import LinkButtonWithDescAndIcon from "Components/buttons/linkButtonWithDescriptionAndIcon";
import { IconDownload, IconPhone, IconQrCode, IconRepeat, IconUnlink, IconUser } from "Components/icons/icons";

import SettingsWrapper from "Components/settings/wrapper/wrapper";
import {
	deviceInfoPath,
	appSettingsPath,
	userSettingsPath,
	clearAppSettingsPath,
	refetchDataPath,
} from "constants/paths";
import { useParams } from "react-router";

import "./dashboard.scss";
import { NetworkStatusContext } from "Context/networkStatus";
import { useContext } from "react";
import ButtonWithDescAndIcon from "Components/buttons/buttonWithDescAndIcon";
import { useState } from "react";
import { getOfflineTicketsAmountFromDatabase, getOfflineTicketsFromIndexedDb } from "indexedDb/dexie";
import { useEffect } from "react";
import { writeExcelFile } from "util/export";
import { CURRENT_VERSION } from "constants/version";

const SettingsDashboard = () => {
	const { apikey } = useParams();
	const online = useContext(NetworkStatusContext);
	const [csvDisabled, setCsvDisabled] = useState(false);

	useEffect(() => {
		try {
			fetchOfflineTicketAmount();
		} catch (error) {
			alert(error);
		}
	}, []);

	const fetchOfflineTicketAmount = async () => {
		const { status, data } = await getOfflineTicketsAmountFromDatabase();
		setCsvDisabled(data?.length === 0 || !data);
	};

	const exportOfflineData = async () => {
		try {
			const { status, data } = await getOfflineTicketsFromIndexedDb();
			writeExcelFile(data, apikey);
		} catch (error) {
			alert(error);
		}
	};

	return (
		<SettingsWrapper id="settings-dashboard">
			<SettingGroup>
				<LinkButtonWithDescAndIcon
					title={<Localized id="user-settings-heading" />}
					desc={<Localized id="user-settings-heading-description" />}
					icon={<IconUser size="25" />}
					to={userSettingsPath(apikey)}
				/>
				<LinkButtonWithDescAndIcon
					title={<Localized id="app-settings-heading" />}
					desc={<Localized id="app-settings-heading-description" />}
					icon={<IconQrCode size="25" />}
					to={appSettingsPath(apikey)}
				/>
			</SettingGroup>

			<SettingGroup>
				<LinkButtonWithDescAndIcon
					title={<Localized id="device-info-settings-heading" />}
					desc={<Localized id="device-info-settings-heading-description" />}
					icon={<IconPhone size="30" />}
					to={deviceInfoPath(apikey)}
				/>
			</SettingGroup>

			{/*
            <SettingGroup>
            <LinkButtonWithDescAndIcon
                title={<Localized id="log-info-settings-heading" />}
                desc={<Localized id="log-info-settings-heading-description" />}
                icon={<IconTasks size="25" />}
                to={scannerDeviceLogPath(apikey)}
            />
        </SettingGroup>
        */}

			<SettingGroup>
				<LinkButtonWithDescAndIcon
					title={<Localized id="refetch-data-settings-heading" />}
					desc={<Localized id="refetch-data-settings-heading-description" />}
					icon={<IconRepeat size="20" />}
					to={refetchDataPath(apikey)}
					disabled={!online}
					disabledInfo={<Localized id="refetch-data-settings-disabled" />}
				/>

				<ButtonWithDescAndIcon
					title={<Localized id="export-offlinedata-heading" />}
					desc={<Localized id="export-offlinedata-heading-description" />}
					icon={<IconDownload size="20" />}
					onClick={exportOfflineData}
					disabled={csvDisabled}
					disabledInfo={<Localized id="export-offlinedata-disabled" />}
				/>
			</SettingGroup>

			<SettingGroup>
				<LinkButtonWithDescAndIcon
					title={<Localized id="clear-storage-settings-heading" />}
					desc={<Localized id="clear-storage-settings-heading-description" />}
					icon={<IconUnlink size="20" />}
					to={clearAppSettingsPath(apikey)}
				/>
			</SettingGroup>
			<div className="version-number">Ver. {CURRENT_VERSION}</div>
		</SettingsWrapper>
	);
};

export const SettingGroup = ({ children }) => {
	return <div className="setting-group">{children}</div>;
};

export default SettingsDashboard;
