import ForwardButton from 'Components/onboard/forwardButton';
import OnboardHeading from 'Components/onboard/heading';
import Paragraph from 'Components/onboard/paragraph';
import { useAsyncStatusHandler, useDbAsyncStatusHandler } from 'hooks/asyncHandler';
import { removeAllDataFromDatabase } from 'indexedDb/dexie';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import './expired.scss';
import { Localized } from '@fluent/react';
import { useContext } from 'react';
import { ConfigurationsContext } from 'Context/configurations';
import { encodeQueryParams } from 'util/api';
import { scannerPath } from 'constants/paths';
import { DB_RESPONSE_OK } from 'constants/dbStatuses';

const ExpiredConfiguration = () => {
    const history = useHistory();
    const {
        asyncState,
        startCall,
        resolveCall
    } = useDbAsyncStatusHandler();

    const {
        asyncState: fetchAsyncState,
        startCall: startFetchCall,
        resolveCall: resolveFetchCall
    } = useAsyncStatusHandler();

    const { scannerConfiguration } = useContext(ConfigurationsContext);

    async function removeConfiguration() {
        startCall();
        localStorage.clear();
        const { status, data } = await removeAllDataFromDatabase();
        resolveCall(status);
        if (status === DB_RESPONSE_OK) {
            history.push('/');
        }
    }


    async function fetchScannerConfiguration() {
        startFetchCall();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/${encodeQueryParams({ apikey: scannerConfiguration.apikey })}`)
        if (response.ok) {
            history.push(scannerPath(scannerConfiguration.apikey));
        }
        resolveFetchCall(response.ok, response.status);
    }

    return <div id="configuration-expired">

        <OnboardHeading><Localized id="configexpired-heading" /></OnboardHeading>

        <Paragraph><Localized id="configexpired-desc" /></Paragraph>

        <p className='options'><Localized id="configexpired-options" /> </p>

        <div className='delete'>
            <Paragraph><b>
                <Localized id="configexpired-delete-heading" />
            </b></Paragraph>
            <Paragraph>
                <Localized id="configexpired-delete-tooltip" />
            </Paragraph>

            <button
                className='delete'
                onClick={removeConfiguration}
            >
                <Localized id="configexpired-delete-button" />
            </button>
        </div>


        <div className='retry'>
            <Paragraph><b>
                <Localized id="configexpired-retry-heading" />
            </b></Paragraph>
            <Paragraph>
                <Localized id="configexpired-retry-tooltip" />
            </Paragraph>

            <Paragraph><Localized id="configexpired-retry-settingname" /> </Paragraph>

            <Paragraph className="name"><b>{scannerConfiguration?.title || '-'}</b></Paragraph>

            {fetchAsyncState.loading && <p className='fetch-status'><Localized id="configexpired-fetch-loading" /></p>}
            {fetchAsyncState.ok === false && <p className='fetch-status'><Localized id="configexpired-fetch-failed" /></p>}

            <button
                className='retry'
                disabled={!scannerConfiguration.apikey}
                onClick={fetchScannerConfiguration}
            >
                <Localized id="configexpired-retry-button" />
            </button>


        </div>




    </div>
}

export default ExpiredConfiguration;