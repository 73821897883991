
import OnboardHeading from 'Components/onboard/heading';
import SupportInfo from 'Components/onboard/supportInfo';
import { SettingGroup } from '../dashboard/dashboard';
import ReactSwitch from "react-switch";

import './appSettings.scss';
import Subheading from 'Components/onboard/subheading';
import Select from 'Components/select/select';
import { useContext } from 'react';
import { ConfigurationsContext } from 'Context/configurations';
import { Localized } from '@fluent/react';
import SettingsWrapper from 'Components/settings/wrapper/wrapper';

const AppSettings = () => {
    const { scanSettings, modifyScanSettings } = useContext(ConfigurationsContext)


    return <SettingsWrapper id="app-settings">
        <SettingGroup>
            <SupportInfo
                title={<Localized id="app-settings-automatic-updates-title" />}
                description={<Localized id="app-settings-automatic-updates-description" />}
            >
                <ReactSwitch checked={scanSettings.autoUpdate} onChange={(checked) => modifyScanSettings('autoUpdate', checked)} />
            </SupportInfo>

            <SupportInfo
                title={<Localized id="app-settings-automatic-updates-frequence-title" />}
                description={<Localized id="app-settings-automatic-updates-frequence-description" />}
                column
            >
                <Select value={scanSettings.updateInterval} disabled={scanSettings.autoUpdate === false} onChange={(e) => modifyScanSettings('updateInterval', parseInt(e.target.value))}>
                    <option value={60000}>
                        <Localized id="app-settings-automatic-updates-60" />
                    </option>
                    <option value={300000}>
                        <Localized id="app-settings-automatic-updates-300" />
                    </option>
                    <option value={600000}>
                        <Localized id="app-settings-automatic-updates-600" />
                    </option>
                </Select>
            </SupportInfo>
        </SettingGroup>
        {/*
        <SettingGroup>
             Needs some discussion about how this should be working. Can be re-enabled after we decide what to do with this
            
                        <SupportInfo
                title={<Localized id="app-settings-scan-popup-title" />}
                description={<Localized id="app-settings-scan-popup-description" />}
            >
                <ReactSwitch checked={scanSettings.keepScanPopupOpen} onChange={(checked) => modifyScanSettings('keepScanPopupOpen', checked)} />
            </SupportInfo>
            

                        <SupportInfo
                title={<Localized id="app-settings-scan-delay-title" />}
                description={<Localized id="app-settings-scan-delay-description" />}
                column
            >
                <Select value={scanSettings.scanDelay} onChange={(e) => modifyScanSettings('scanDelay', parseInt(e.target.value))}>
                    <option value={1000}>
                        <Localized id="app-settings-scan-delay-1" />
                    </option>
                    <option value={2000}>
                        <Localized id="app-settings-scan-delay-2" />

                    </option>
                    <option value={3000}>
                        <Localized id="app-settings-scan-delay-3" />

                    </option>
                    <option value={5000}>
                        <Localized id="app-settings-scan-delay-5" />
                    </option>
                    <option value={10000}>
                        <Localized id="app-settings-scan-delay-10" />
                    </option>
                </Select>
            </SupportInfo>
            
            

        </SettingGroup>

                <SettingGroup>
            <SupportInfo
                title={<Localized id="app-settings-logging-title" />}
                description={<Localized id="app-settings-logging-description" />}
            >
                <ReactSwitch checked={scanSettings.logging} onChange={(checked) => modifyScanSettings('logging', checked)} />
            </SupportInfo>
        </SettingGroup>
        
        */}

    </SettingsWrapper>
}

export default AppSettings;