/** @format */

import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import OnboardWrapper from "Components/onboard/onboardWrapper";
import Paragraph from "Components/onboard/paragraph";
import { appOnboardAcImportDataPath, boothPath } from "constants/paths";
import { ConfigurationsContext } from "Context/configurations";
import { useAsyncStatusHandler } from "hooks/asyncHandler";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";

import "./appName.scss";
import { Localized } from "@fluent/react";

const AppName = () => {
	const { apikey = "" } = useParams();
	const [deviceName, setDeviceName] = useState("");
	const history = useHistory();
	const { asyncState: asyncDbState, startCall: startDbCall, resolveCall: resolveDbCall } = useAsyncStatusHandler();
	const { modifyScanSettings, allowedBoothNames = [], boothMode } = useContext(ConfigurationsContext);

	const submit = async () => {
		startDbCall();
		const { status } = await modifyScanSettings("device_name", deviceName);
		if (status === 100) {
			resolveDbCall(true, 200);
			history.push(appOnboardAcImportDataPath(apikey));
		}
	};

	function handleChange(e) {
		setDeviceName(e.target.value);
	}

	const onEnter = e => {
		if (e.key === "Enter" && deviceName.length > 0) {
			e.target.blur();
			submit();
		}
	};

	return (
		<OnboardWrapper>
			<OnboardHeading>
				<Localized id="onboard-appname-title" />
			</OnboardHeading>
			<Paragraph>
				<Localized id="onboard-appname-desc" />
			</Paragraph>

			<div className="device-name-input-container">
				<label>
					<Localized id="onboard-appname-label" />
				</label>

				{allowedBoothNames.length > 0 ? (
					<select value={deviceName} onChange={e => setDeviceName(e.target.value)}>
						<Localized id="onboard-appname-default">
							<option default hidden>
								-- Valitse --
							</option>
						</Localized>

						{allowedBoothNames.map((b, i) => (
							<option key={b + i} value={b}>
								{b}
							</option>
						))}
					</select>
				) : (
					<Localized id="onboard-appname-input" attrs={{ placeholder: true }}>
						<input value={deviceName} placeholder="device name" onChange={handleChange} onKeyUp={onEnter} />
					</Localized>
				)}
			</div>

			<ForwardButton type="button" onClick={submit} disabled={!deviceName}>
				<Localized id="onboard-appname-continue" />
			</ForwardButton>
			<Paragraph>
				{asyncDbState.loading ? (
					<Localized id="onboard-appname-loading" />
				) : (
					<Localized id="onboard-appname-tip" />
				)}
			</Paragraph>
		</OnboardWrapper>
	);
};

export default AppName;
