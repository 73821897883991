/** @format */

import {
	ALREADY_SCANNED,
	FOUND,
	NOTFOUND,
	NOT_VALID_YET,
	NOT_VALID_ANYMORE,
	FLAG_RED,
	FLAG_GREEN,
	FLAG_YELLOW,
} from "constants/scanTypes";
import moment, { isMoment } from "moment/moment";
import { createRef, useCallback, useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { encodeQueryParams } from "util/api";
import { NetworkStatusContext } from "./networkStatus";
import { useLocalStorage } from "hooks/localStorage";
import { Alert } from "@mantine/core";
import {
	getBoothscanDataFromDatabase,
	getOfflineTicketsAmountFromDatabase,
	getScansAmountFromDatabase,
	getTicketListFromDatabase,
	getTicketsAmountFromDatabase,
	getTicketValidationDataFromDatabase,
	getUniqueScansAmountFromDatabase,
	requestLastScanIdFromIndexedDb,
	writeOfflineTicketToDatabase,
	writeScansToIndexedDb,
} from "indexedDb/dexie";
import { LoggingContext } from "./logging";
import { useInterval } from "@mantine/hooks";
import { DB_RESPONSE_ERROR, DB_RESPONSE_OK } from "constants/dbStatuses";
import { scansUrl, ticketsUrl } from "constants/apiurls";
import { ConfigurationsContext } from "./configurations";
import { Localized } from "@fluent/react";
import { IconCheck, IconInfoCircle, IconTimes } from "Components/icons/icons";
import { useNotificationContext } from "./notificationContext";
const { createContext } = require("react");

export const TicketStorageContext = createContext({});

const TicketStorageContextProvider = ({ children }) => {
	const [lastSync, setLastSync] = useLocalStorage("liveto-scanner-tickets-sync-timestamp", null);
	const { scanSettings, boothMode } = useContext(ConfigurationsContext);
	const [ticketsAmount, setTicketsAmount] = useState(null);
	const [scansAmount, setScansAmount] = useState(null);
	const [uniqueScansAmount, setUniqueScansAmount] = useState(null);
	const [offlineTicketsAmount, setOfflineTicketsAmount] = useState(null);
	const { handleErrorNotification, handleSuccessNotification } = useNotificationContext();

	const { scanMode, events, scannerConfiguration } = useContext(ConfigurationsContext);
	const online = useContext(NetworkStatusContext);

	//https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
	const onlineRef = useRef();
	onlineRef.current = online;

	const modeRef = useRef();
	modeRef.current = scanMode.mode;

	const sectionRef = useRef();
	sectionRef.current = typeof scanMode.section === "string" ? parseInt(scanMode.section) : scanMode.section;
	// -----------------------------------------------------

	const scanTemplate = (ticketId, scanType, section, device) => {
		return {
			ticket_id: ticketId,
			scanned: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
			scan_type: scanType, // [entry, exit], section, booth
			section_id: section, // section id (number), null
			device_id: device,
			device_label: device,
			metadata: {},
		};
	};

	async function persistScanToBackendOrIndexedDb(scanData) {
		const ticketToScan = { ...scanData };

		if (!onlineRef.current) {
			const response = await writeOfflineTicketToDatabase(ticketToScan);
			if (response.status === DB_RESPONSE_OK) {
				handleSuccessNotification({
					title: <Localized id="scanview-notification-innermemory-success-title">Success!</Localized>,
					children: (
						<Localized id="scanview-notification-innermemory-success-body">
							{"Scan stored to device memory"}
						</Localized>
					),
				});
			}
			if (response.status === DB_RESPONSE_ERROR) {
				handleErrorNotification({
					title: <Localized id="scanview-notification-innermemory-error-title">Error!</Localized>,
					children: (
						<Localized id="scanview-notification-innermemory-error-body">
							{"Could not store the scan to device memory"}
						</Localized>
					),
				});
			}
			return;
		}

		if (!ticketToScan.section_id) {
			delete ticketToScan.section_id;
		}
		// If online, post the new scan to backend, and update the scanData

		// handle the current scan data

		// Post the new scan
		const postTicketsResponse = await fetch(scansUrl, {
			method: "POST",
			headers: { "Content-Type": "Application/json" },
			body: JSON.stringify({
				apikey: scannerConfiguration.apikey,
				tickets: [ticketToScan],
				device: scanSettings.device_name || "Unnamed device",
			}),
		});

		if (postTicketsResponse.ok && !boothMode) {
			const lastScanResponse = await requestLastScanIdFromIndexedDb();

			if (lastScanResponse.status === DB_RESPONSE_OK) {
				// Fetch the new scans
				const scansResponse = await fetch(
					`${scansUrl}${encodeQueryParams({
						apikey: scannerConfiguration.apikey,
						after: lastScanResponse.data.id,
					})}`
				);
				if (scansResponse.ok) {
					const scansData = await scansResponse.json();
					if (scansData.length > 0) {
						await writeScansToIndexedDb(scansData);
					}
				} else {
					const errorData = await scansResponse.json();
					handleErrorNotification({
						title: (
							<Localized
								id="apirequest-error-newscans"
								vars={{ status: scansResponse.status }}
							>{`Error fetching new scans: ${scansResponse.status}`}</Localized>
						),
						children: JSON.stringify(errorData),
					});
				}
			}
		}

		if (!postTicketsResponse.ok) {
			const errorJson = await postTicketsResponse.json();
			handleErrorNotification({
				title: (
					<Localized
						id="scanview-notification-booth-error-title"
						vars={{ status: postTicketsResponse.status }}
					>{`Error: ${postTicketsResponse.status}`}</Localized>
				),
				children: JSON.stringify(errorJson),
			});
		}
		if (postTicketsResponse.ok && boothMode) {
			handleSuccessNotification({
				title: <Localized id="scanview-read-success">Success!</Localized>,
				children: (
					<Localized id="scanview-notification-booth-success-body">Contact saved successfully</Localized>
				),
			});
		}
	}

	async function manualVerifyTicket(ticketId, metaData, direction) {
		const type = modeRef.current === "entry" || modeRef.current === "exit" ? direction : "section";
		let scanningData = scanTemplate(
			ticketId,
			type,
			sectionRef.current,
			scanSettings.device_name || "Unnamed device"
		);

		scanningData["metadata"] = metaData;
		scanningData["metadata"]["direction"] = direction; // Setup the direction that was manually chosen, used to determine the section strict rules
		scanningData["metadata"]["manual"] = true;

		return await persistScanToBackendOrIndexedDb(scanningData);
	}

	async function updateTicketAmountFromDatabaseToState() {
		const getResponse = await getTicketsAmountFromDatabase();
		if (getResponse.status === DB_RESPONSE_OK) {
			setTicketsAmount(getResponse.data);
		}
	}

	async function updateUniqueScansAmountFromDatabaseToState() {
		const getResponse = await getUniqueScansAmountFromDatabase();
		if (getResponse.status === DB_RESPONSE_OK) {
			setUniqueScansAmount(getResponse.data);
		}
	}

	async function updateUnsyncedScanAmountFromDatabase() {
		const getResponse = await getOfflineTicketsAmountFromDatabase();
		if (getResponse.status === DB_RESPONSE_OK) {
			setOfflineTicketsAmount(getResponse.data);
		}
	}

	async function updateTotalScansAmountFromDatabaseToState() {
		const getResponse = await getScansAmountFromDatabase();
		if (getResponse.status === DB_RESPONSE_OK) {
			setScansAmount(getResponse.data);
		}
	}

	async function requestTickets() {
		return await getTicketListFromDatabase();
	}

	useEffect(() => {
		updateTicketAmountFromDatabaseToState();
		updateUniqueScansAmountFromDatabaseToState();
		updateUnsyncedScanAmountFromDatabase();
		updateTotalScansAmountFromDatabaseToState();
	}, []);

	const ctx = {
		requestTickets,
		lastSync,
		setLastSync,
		updateTicketAmountFromDatabaseToState,
		updateUniqueScansAmountFromDatabaseToState,
		updateUnsyncedScanAmountFromDatabase,
		ticketsAmount,
		scansAmount,
		uniqueScansAmount,
		offlineTicketsAmount,
		manualVerifyTicket,
		persistScanToBackendOrIndexedDb,
	};
	return <TicketStorageContext.Provider value={ctx}>{children}</TicketStorageContext.Provider>;
};

export default TicketStorageContextProvider;
