import { Localized } from '@fluent/react';
import { IconChevronLeft } from 'Components/icons/icons';
import { boothPath } from 'constants/paths';
import { TicketStorageContext } from 'Context/ticketStorageContext';
import moment from 'moment/moment';
import { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import './header.scss';

const BoothHeader = () => {
    const { pathname } = useLocation();
    const { apikey } = useParams();
    const history = useHistory();
    const { lastSync } = useContext(TicketStorageContext);

    function goBack() {
        history.push(boothPath(apikey));
    }
    return <>
        <div className="booth-header">
            {pathname !== boothPath(apikey) && <button className='back-button' onClick={goBack}>
                <IconChevronLeft />
            </button>}

            <p className="booth-slogan"><Localized id="header-booth" /></p>
            <small>
                <Localized id="header-last-sync" vars={{lastSync: moment(lastSync).format('DD.MM.YYYY HH:mm:ss')}} />
            </small>
        </div>

    </>
}

export default BoothHeader;