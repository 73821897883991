/** @format */

import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import OnboardWrapper from "Components/onboard/onboardWrapper";
import Paragraph from "Components/onboard/paragraph";
import Subheading from "Components/onboard/subheading";
import SupportInfo from "Components/onboard/supportInfo";
import AsyncHandler from "Components/wrappers/apiCallHandler";
import { eventsUrl } from "constants/apiurls";
import { appOnboardACNamePath, appOnboardBoothNamePath } from "constants/paths";
import { ConfigurationsContext } from "Context/configurations";
import { useAsyncStatusHandler, useDbAsyncStatusHandler } from "hooks/asyncHandler";
import moment from "moment/moment";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { encodeQueryParams } from "util/api";
import "./appConfigInfo.scss";
import { Localized } from "@fluent/react";
import { useNotificationContext } from "Context/notificationContext";

const AppConfigInfo = () => {
	const { apikey = "" } = useParams();
	const history = useHistory();

	const { persistScannerConfigurations } = useContext(ConfigurationsContext);
	const { handleErrorNotification, handleSuccessNotification } = useNotificationContext();

	const [eventData, setEventData] = useState({});
	const [boothOptions, setBoothOptions] = useState({});
	const [configuration, setConfiguration] = useState({});
	const [scannerConfiguration, setScannerConfiguration] = useState(null);
	// Api call states
	const { asyncState, startCall, resolveCall } = useAsyncStatusHandler();
	const {
		asyncState: eventsApiState,
		startCall: startEventsApiCall,
		resolveCall: resolveEventsApiCall,
	} = useAsyncStatusHandler();

	// DB Sync states
	const {
		asyncState: asyncConfDbState,
		startCall: startConfDbCall,
		resolveCall: resolveConfDbCall,
	} = useDbAsyncStatusHandler();
	const {
		asyncState: asyncEventsDbState,
		startCall: startEventsDbCall,
		resolveCall: resolveEventsDbCall,
	} = useDbAsyncStatusHandler();

	const [data, setData] = useState({
		title: "",
		events: [],
		expiration_time: "",
	});

	async function fetchConfiguration(apikey) {
		startCall();
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/${encodeQueryParams({
				apikey: apikey,
			})}`
		);
		if (response.ok) {
			const json = await response.json();
			const eventDataJson = json.event_data;
			const boothOptionsJson = json.booth_config;
			const configurationJson = {
				apikey: json.apikey,
				expiration_time: json.expiration_time,
				title: json.title,
				events: json.events,
			};
			setConfiguration(configurationJson);
			setEventData(eventDataJson);
			setBoothOptions(boothOptionsJson);
			setScannerConfiguration(json);
		} else {
			const errorData = await response.json();
			handleErrorNotification({
				title: (
					<Localized
						id="apirequest-error-configuration"
						vars={{ status: response.status }}
					>{`Error fetching scanner configuration: ${response.status}`}</Localized>
				),
				children: JSON.stringify(errorData),
			});
		}
		resolveCall(response.ok, response.status);
	}

	async function submit() {
		// Sync configuration to indexedDb
		startConfDbCall();
		const { status } = await persistScannerConfigurations(scannerConfiguration);

		if (status === 100) {
			resolveConfDbCall(status);
			history.push(boothOptions.booth_mode_only ? appOnboardBoothNamePath(apikey) : appOnboardACNamePath(apikey));
		}
	}

	function renderText() {
		if (asyncConfDbState.loading) {
			return <Localized id="onboard-configinfo-tip-loading-conf" />;
		}
		if (eventsApiState.loading) {
			return <Localized id="onboard-configinfo-tip-events" />;
		}
		if (asyncEventsDbState.loading) {
			return <Localized id="onboard-configinfo-tip-eventsdb" />;
		}

		return <Localized id="onboard-configinfo-tip" />;
	}

	useEffect(() => {
		fetchConfiguration(apikey);
	}, []);

	return (
		<OnboardWrapper>
			<OnboardHeading>
				<Localized id="onboard-configinfo-heading" />
			</OnboardHeading>

			<AsyncHandler {...asyncState} size="50" error={<Localized id="onboard-configinfo-notfound" />}>
				<Subheading>
					<Localized id="onboard-configinfo-info" />
				</Subheading>

				<div className="setting-columns">
					<b>
						<Localized id="onboard-configinfo-title" />
					</b>
					<div className="setting-columns-content">
						<small>{configuration.title}</small>
					</div>
				</div>
				<div className="setting-columns">
					<b>
						<Localized id="onboard-configinfo-expiration" />
					</b>
					<div className="setting-columns-content">
						<small>{moment(configuration?.expiration_time).format("DD.MM.YYYY HH:mm")}</small>
					</div>
				</div>

				{!boothOptions?.booth_mode_only && (
					<div className="setting-columns">
						<b>
							<Localized id="onboard-configinfo-events" />
						</b>
						<div className="setting-columns-content">
							{configuration?.events &&
								configuration.events.length > 0 &&
								configuration?.events.map(e => <small key={e}>{eventData[e].event_name}</small>)}
						</div>
					</div>
				)}

				<div className="setting-columns">
					<b>
						<Localized id="onboard-configinfo-mode" />
					</b>
					<div className="setting-columns-content">
						<small>
							{boothOptions?.booth_mode_only ? (
								<Localized id="onboard-configinfo-mode-booth" />
							) : (
								<Localized id="onboard-configinfo-mode-ac" />
							)}
						</small>
					</div>
				</div>

				<ForwardButton type="button" onClick={submit}>
					<Localized id="onboard-configinfo-continue" />
				</ForwardButton>
				<Paragraph>{renderText()}</Paragraph>
			</AsyncHandler>
		</OnboardWrapper>
	);
};

export default AppConfigInfo;
