/** @format */

import { NavLink } from "react-router-dom";

import "./linkButtonWithDescriptionAndIcon.scss";

const LinkButtonWithDescAndIcon = ({ to, title, desc, icon, disabled, disabledInfo = "" }) => {
    return (
		<NavLink
			to={to}
			className={`link-button-with-description-and-icon ${disabled ? "disabled" : ""}`}
		>
			<div className="icon-container">{icon}</div>
			<div className="texts">
				<b>{title}</b>
				<p>{desc}</p>
                {disabled && <p className="info">{disabledInfo}</p>}
			</div>
		</NavLink>
	);
};
export default LinkButtonWithDescAndIcon;
