
import { FLAG_RED } from 'constants/scanTypes';
import './extraInfo.scss';
import { IconQrCode } from 'Components/icons/icons';
import { Localized } from '@fluent/react';
import { useState } from 'react';
import { useContext } from 'react';
import { TicketStorageContext } from 'Context/ticketStorageContext';
import { useEffect } from 'react';

const ExtraInfo = ({ scanData, setScanData, saveScan }) => {
    const { persistScanToBackendOrIndexedDb } = useContext(TicketStorageContext);

    const { flag, data } = scanData;

    const [boothNotes, setBoothNotes] = useState("");


    if (flag === FLAG_RED) {
        return <StatusRenderer setScanData={setScanData} />
    }


    async function saveScan() {
        data['metadata'] = { ...data.metadata, ['booth_notes']: boothNotes }
        await persistScanToBackendOrIndexedDb({ ...data })
        setScanData(null);
    }

    return <div className='extra-info'>
        <h1>
            <Localized id="booth-extra-heading" />
        </h1>
        <div className='textarea-container'>
            <textarea value={boothNotes} onChange={e => setBoothNotes(e.target.value)} rows={5} maxLength={200}></textarea>
            <small>{boothNotes.length} / 200</small>
        </div>

        <p>
            <Localized id="booth-extra-info" />
        </p>


        <button onClick={saveScan}>
            <Localized id="booth-extra-save" />
        </button>
    </div>
}


// Nimi, Puhelin, Sähköposti, titteli, yritys

const StatusRenderer = ({ setScanData }) => {
    const resetStatus = () => {
        setScanData(null);
    }
    return <div className='failed-scan'>
        <h1><Localized id="ticket-status-not-found" /></h1>

        <IconQrCode size="150" className="icon" />

        <button className='close-status-button' onClick={() => resetStatus()}>
            <Localized id="qr-reader-status-close" />
        </button>
    </div>
}

export default ExtraInfo;