import { Localized } from "@fluent/react";
import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import OnboardWrapper from "Components/onboard/onboardWrapper";
import Paragraph from "Components/onboard/paragraph";
import { appOnboardConfigurationInfoPath } from "constants/paths";
import { useParams } from "react-router";


const AppLoadConfig = () => {
    const { apikey = "" } = useParams();
    return <OnboardWrapper>
        <OnboardHeading>
            <Localized id="onboard-apploadconfig-heading" />
        </OnboardHeading>
        <Paragraph>
            <Localized id="onboard-apploadconfig-desc" />
        </Paragraph>

        <b>{apikey}</b>

        <ForwardButton to={appOnboardConfigurationInfoPath(apikey)}>
            <Localized id="onboard-apploadconfig-download" />
        </ForwardButton>
        <Paragraph>
        <Localized id="onboard-apploadconfig-tip" />
        </Paragraph>
    </OnboardWrapper>
}

export default AppLoadConfig;