import { createContext, useState, useEffect } from 'react';
import { useInterval } from '@mantine/hooks';
import { useContext } from 'react';
import { LoggingContext } from './logging';
import OfflineStatusViewer from 'Components/displayOfflineStatus/offlineStatus';


function needsPolling() {
    const unsupportedUserAgentsPattern = /Windows.*Firefox|Linux.*Chrome/;
    if (typeof navigator !== 'undefined' && unsupportedUserAgentsPattern.test(navigator.userAgent)) {
        return true;
    }
    return false;
}



export const NetworkStatusContext = createContext(false);

const NetworkStatusProvider = ({ children }) => {
    const { addLogEntry } = useContext(LoggingContext);

    const [online, setOnline] = useState(navigator.onLine);



    const goOnline = () => setOnline(true);
    const goOffline = () => setOnline(false);

    let polling = null;


    const ping = ({ url, timeout }) => {
        return new Promise((resolve, reject) => {
            const isOnline = () => resolve();
            const isOffline = () => reject();

            const xhr = new XMLHttpRequest();

            xhr.onerror = isOffline;
            xhr.ontimeout = isOffline;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                    if (xhr.status) {
                        isOnline();
                    } else {
                        isOffline();
                    }
                }
            };

            xhr.open('HEAD', url);
            xhr.timeout = timeout;
            xhr.send();
        });
    };



    useEffect(() => {
        window.addEventListener('online', goOnline);
        window.addEventListener('offline', goOffline);

        return () => {
            window.removeEventListener('online', goOnline);
            window.removeEventListener('offline', goOffline);
        };
    }, []);

    const interval = useInterval(async () => {
        try {
            await ping({ url: 'https://ipv4.icanhazip.com/', timeout: 2000 });
            setOnline(true);
        } catch (error) {
            setOnline(false);
        }
    }, 2000);

    useEffect(() => {
        if (!online) {
            addLogEntry({ event: 'network-lost', data: 'Lost network connection' })
            interval.start();
        }
        if (online && interval.active) {
            addLogEntry({ event: 'network-regain', data: 'Regain network connection' })

            interval.stop();

        }
    }, [online])


    return <NetworkStatusContext.Provider value={online}>
        <OfflineStatusViewer online={online} />
        {children || null}
    </NetworkStatusContext.Provider>
}

export default NetworkStatusProvider