import { useSetState } from '@mantine/hooks';
import { useState, useCallback } from 'react'



export const useAsyncStatusHandler = (serversideInitialFetch = false) => {
    const [asyncState, setAsyncState] = useSetState({
        status: serversideInitialFetch ? 200 : null,
        loading: false,
        ok: serversideInitialFetch ? true : null
    })

    const startCall = useCallback(() => {
        setAsyncState({ status: null, ok: null, loading: true })
    }, [])

    const resolveCall = useCallback((ok, status) => {
        setAsyncState({ status: status, ok: ok, loading: false })
    }, [])

    return { asyncState, startCall, resolveCall };
}

export const useDbAsyncStatusHandler = () => {
    const [asyncState, setAsyncState] = useSetState({
        status: null,
        loading: false,
    })

    const startCall = useCallback(() => {
        setAsyncState({ status: null, loading: true })
    }, [])

    const resolveCall = useCallback((status) => {
        setAsyncState({ status: status, loading: false })
    }, [])

    return { asyncState, startCall, resolveCall };
}