import { Localized } from "@fluent/react";
import { useSetState, useTimeout } from "@mantine/hooks";
import OnboardHeading from "Components/onboard/heading";
import SupportInfo from "Components/onboard/supportInfo";
import Select from "Components/select/select";
import Input from "Components/input/input";
import SettingsWrapper from "Components/settings/wrapper/wrapper";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { ConfigurationsContext } from "Context/configurations";
import { useDbAsyncStatusHandler } from "hooks/asyncHandler";
import { getUserSettingsFromDatabase, modifyUserSetting, resetUserSettings } from "indexedDb/dexie";
import { useContext, useState } from "react";
import { useEffect } from "react";
import ReactSwitch from "react-switch";
import { LocaleContext } from "util/localizationProvider";
import { SettingGroup } from "../dashboard/dashboard";

import './userSettings.scss';
import { isIOS, useDeviceSelectors } from "react-device-detect";

const UserSettings = () => {
    const [, selectors] = useDeviceSelectors(window.navigator.userAgent)

    const { userSettings, scanSettings, modifyScanSettings, modifyUserSetting, allowedBoothNames } = useContext(ConfigurationsContext);
    const { asyncState, startCall, resolveCall } = useDbAsyncStatusHandler();
    const { start, stop } = useTimeout(() => resolveCall({ status: null, loading: false }), 1000)
    const {
        handedness,
        language,
        wakelock,
    } = userSettings;

    const { device_name } = scanSettings

    const [name, setName] = useState(device_name);


    async function saveName() {
        startCall();
        const { status } = await modifyScanSettings("device_name", name);
        resolveCall(status);
    }


    function hasBrowserSupportForWakelock() {
        if (isIOS) {
            const browserVersion = selectors.browser.version;
            const splittedVersioning = browserVersion.split('.');

            if (splittedVersioning[0] > 16 && splittedVersioning[1] > 4) {
                return true;
            }
            return false;
        }

        return true;
    }

    useEffect(() => { hasBrowserSupportForWakelock() }, [])

    useEffect(() => {
        if (asyncState.status === DB_RESPONSE_OK) {
            start();
        }
    }, [asyncState.status])



    const wakelockSupported = hasBrowserSupportForWakelock();



    return <SettingsWrapper id="user-settings">

        <SettingGroup>
            <SupportInfo
                title={<Localized id="user-settings-devicename-title" />}
                description={<Localized id="user-settings-devicename-description" />}
                column
            >
                {allowedBoothNames.length > 0 ?
                    <Select value={name} onChange={e => setName(e.target.value)}>
                        {allowedBoothNames.map(bName => <option value={bName}>{bName}</option>)}
                    </Select>
                    :
                    <Input value={name} onChange={e => setName(e.target.value)} />
                }
            </SupportInfo>
            {asyncState.status === DB_RESPONSE_OK && <small className="name-change-status"><Localized id="user-settings-devicename-changed" /></small>}
            {name !== device_name && <button className="save-changes" disabled={name === device_name} onClick={saveName}>
            <Localized id="user-settings-devicename-save" />
            </button>}


        </SettingGroup>

        <SettingGroup>
            <SupportInfo
                title={<Localized id="user-settings-handedness-title" />}
                description={<Localized id="user-settings-handedness-description" />}
                column
            >
                <Select value={handedness} onChange={e => modifyUserSetting('handedness', e.target.value)}>
                    <option value="left">
                        <Localized id="user-settings-handedness-left" />
                    </option>
                    <option value="right">
                        <Localized id="user-settings-handedness-right" />
                    </option>
                </Select>
            </SupportInfo>
            <SupportInfo
                title={<Localized id="user-settings-language-title" />}
                description={<Localized id="user-settings-language-description" />}
                column
            >
                <Select value={language} onChange={e => modifyUserSetting('language', e.target.value)}>
                    <option value="fi">
                        <Localized id="user-settings-language-fi" />
                    </option>
                    <option value="en">
                        <Localized id="user-settings-language-en" />
                    </option>
                </Select>
            </SupportInfo>
        </SettingGroup>

        <SettingGroup>
            <SupportInfo
                title={<Localized id="user-settings-wakelock-title" />}
                description={<Localized id="user-settings-wakelock-description" />}
            >
                <ReactSwitch checked={wakelock} disabled={!wakelockSupported} onChange={(checked) => modifyUserSetting('wakelock', checked)} />
            </SupportInfo>
            {!wakelockSupported && <p className="no-support"><Localized id="user-settings-wakelock-notsupported" /></p>}

        </SettingGroup>
    </SettingsWrapper>
}

export default UserSettings;