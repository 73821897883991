/** @format */

import { ConfigurationsContext } from "Context/configurations";
import * as Lvt from "StyledComponents/components";
import moment from "moment";
import { useContext } from "react";
import { useParams } from "react-router";
import { IconCheck, IconTicket } from "Components/icons/icons";
import { Localized, useLocalization } from '@fluent/react';
import './eventDetail.scss';



export const ScannerEventDetails = props => {
	const { slug } = useParams();
	const { events = [] } = useContext(ConfigurationsContext);

	const event = events.find(e => e.slug === slug);

	const { products = [], event_name = "", access_controls = [], venuemap = {} } = event;

	return (
		<div id="scanner-event-details">
			<h1 className="event-name">
				{event_name}
			</h1>

			<h2 className="products-ac-title">
				<Localized id='events-event-product-title' />
			</h2>

			{products.map(p => (<ProductList p={p} access_controls={access_controls} venuemap={venuemap} key={p.name} />))}
		</div>
	);
};

const ProductList = props => {
	const { p, access_controls, venuemap } = props;

	const validity = checkProductValidity(p.valid_from, p.valid_until);

	function checkProductValidity(valid_from, valid_until) {
		const validFrom = valid_from ? `${moment(valid_from).format("DD.MM.YYYY hh:mm")}` : "";
		const validUntil = valid_until ? `${moment(valid_until).format("DD.MM.YYYY hh:mm")}` : "";
		const { l10n } = useLocalization();

		let mergedString = l10n.getString('events-event-valid-always');

		if (validFrom && !validUntil) {
			mergedString = `${l10n.getString('events-event-valid-from')} ${validFrom}`;
		} else if (!validFrom && validUntil) {
			mergedString = `${l10n.getString('events-event-valid-until')} ${validUntil}`;
		} else if (validFrom && validUntil) {
			mergedString = `${l10n.getString('events-event-valid')} ${validFrom} - ${validUntil}`;
		}
		return mergedString;
	}


	return (
		<div className="product">
			<Lvt.Col className="product-info">
				<h3 className="product-name">Tuotekategoria: {p.name}</h3>
				<p className="product-validity">{validity}</p>
			</Lvt.Col>
			<div className="variant-list">
				{p.variants.map(v => {
					const foundAccessControls = [];
					const sections = [];

					access_controls.forEach(ac => {
						if (ac.products.includes(v.id)) {
							foundAccessControls.push(ac.id);
						}
					});

					const suitableForEntry = foundAccessControls.includes(venuemap.access_control);

					if (venuemap.sections) {
						venuemap.sections.forEach(s => {
							if (foundAccessControls.includes(s.access_control)) {
								sections.push(s);
							}
						});
					}

					return (
						<VariantList v={v} suitableForEntry={suitableForEntry} sections={sections} key={v.id} />
					);
				})}
			</div>
		</div>
	);
};

const VariantList = props => {
	const { v, suitableForEntry, sections } = props;
	return (
		<div className="variants">

			<div className="variant-title">
				<IconTicket size="16" />
				<p>Tuote: {v.name}</p>
			</div>

			<div className="variant-info">

				{suitableForEntry && <div className="access-control">
					<IconCheck style={{ color: 'green' }} />
					<Localized id='events-event-access-entry' />
				</div>}

				{sections
					.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() && 1) || -1)
					.map(s => (
						<div className="access-control">
							<IconCheck style={{ color: 'green' }} />
							<p>{s.name}</p>
						</div>
					))}
			</div>
		</div>
	);
};

export default ScannerEventDetails;
