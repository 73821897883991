import './supportInfo.scss';

const SupportInfo = ({ title, description, column = false, children }) => {
    return <div className={`support-info`}>
        <h3>{title}</h3>

        <div className={`info${column ? ' column' : ''}`}>
            <p>{description}</p>
            <div className='children'>
                {children}
            </div>
        </div>

    </div>
}

export default SupportInfo;