/** @format */

import { useEffect } from "react";
import "./contacts.scss";
import { getAllBoothScansByDeviceLabelFromIndexedDb } from "indexedDb/dexie";
import { useContext } from "react";
import { ConfigurationsContext } from "Context/configurations";
import { useState } from "react";
import moment from "moment";
import { Localized } from "@fluent/react";

const SHOW_AMOUNT = 10;
const BoothContacts = () => {
	const { scanSettings } = useContext(ConfigurationsContext);

	const [contacts, setContacts] = useState([]);

	const [showAmount, setShowAmount] = useState(SHOW_AMOUNT);

	async function getContacts() {
		const response = await getAllBoothScansByDeviceLabelFromIndexedDb(scanSettings.device_name);
		setContacts(response.data);
	}
	useEffect(() => {
		getContacts();
	}, []);

	return (
		<div id="booth-contacts">
			<h1>
				<Localized id="booth-contacts-heading" />
			</h1>
			{contacts.slice(0, showAmount).map((contact, idx) => (
				<Contact key={`contact-${idx}`} metadata={contact.metadata} scanned={contact.scanned} idx={idx + 1} />
			))}
			{showAmount < contacts.length && (
				<button onClick={() => setShowAmount(showAmount + SHOW_AMOUNT)}>
					<Localized id="booth-contacts-showmore" />
				</button>
			)}
		</div>
	);
};

const Contact = ({ metadata, scanned, idx }) => {
	return (
		<div className="contact">
			<h2>
				<Localized id="booth-contacts-contact" vars={{ index: idx }} />
			</h2>
			<small>{moment(scanned).format("DD.MM.YYYY HH:mm")}</small>
			<p>
				<Localized id="booth-contacts-contact-notes" />
			</p>
			<p className="notes">
				{metadata?.booth_notes ? metadata.booth_notes : <Localized id="booth-contacts-contact-nonotes" />}
			</p>
		</div>
	);
};

export default BoothContacts;
