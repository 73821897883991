import React, { Children, useEffect, useState } from "react";

import { negotiateLanguages } from "@fluent/langneg";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import { ReactLocalization, LocalizationProvider } from "@fluent/react";

import LoadPage from "Components/loadPage/loadPage";
import { getLanguageSelectionFromDatabase } from "indexedDb/dexie";
import { createContext } from "react";
import { DB_RESPONSE_OK } from "constants/dbStatuses";

const DEFAULT_LOCALE = "fi";
const AVAILABLE_LOCALES = {
    en: "English",
    fi: "Finnish",
};

async function fetchMessages(locale) {
    let messages = "";

    let response = await fetch(`/locales/${locale}/locale.ftl`, {
        headers: {
            'pragma': 'no-cache'
        }
    });
    let text = await response.text();
    messages += text;
    messages += '\n';

    return [locale, messages];
}

function* lazilyParsedBundles(fetchedMessages) {
    for (let [locale, messages] of fetchedMessages) {
        let resource = new FluentResource(messages);
        let bundle = new FluentBundle(locale);
        bundle.addResource(resource);
        yield bundle;
    }
}

export const LocaleContext = createContext({});



function AppLocalizationProvider(props) {
    let [l10n, setL10n] = useState(null);

    const [language, setLanguage] = useState(null);

    async function getLanguage() {
        const { status, data: language } = await getLanguageSelectionFromDatabase();
        if (status === DB_RESPONSE_OK) {
            setLanguage(language);
        }
    }

    useEffect(() => {
        getLanguage();
    }, [])


    useEffect(() => {
        changeLocales([language]);
    }, [language]);


    async function changeLocales(userLocales) {
        let currentLocales = negotiateLanguages(userLocales, Object.keys(AVAILABLE_LOCALES), { defaultLocale: DEFAULT_LOCALE });

        let fetchedMessages = await Promise.all(currentLocales.map(fetchMessages));

        let bundles = lazilyParsedBundles(fetchedMessages);
        setL10n(new ReactLocalization(bundles));
    }

    if (l10n === null) {
        return <LoadPage text="Setting up localization" />;
    }

    const ctx = {
        language, setLanguage
    }
    return <LocaleContext.Provider value={ctx}>
        <LocalizationProvider l10n={l10n}>
            {Children.only(props.children)}
        </LocalizationProvider>
    </LocaleContext.Provider>


}

export default AppLocalizationProvider;
