/** @format */

import "./card.scss";

const Card = ({ title = "", bgColor = "white", children, contentWrap = true, className = "" }) => {
	const bg = bgColor ? ` ${bgColor}` : "";
	return (
		<div className={`card${bg} ${className}`}>
			{title && (
				<div className="title">
					<h2>{title}</h2>
				</div>
			)}
			{contentWrap ? <div className="content">{children}</div> : children}
		</div>
	);
};

export default Card;
