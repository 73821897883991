/** @format */

import { IconTimes } from "Components/icons/icons";
import { useState } from "react";

import "./scanOptionsDrawer.scss";
import { Localized, useLocalization } from "@fluent/react";
import { TextInput } from "@mantine/core";
import { useRef } from "react";
import { useEffect } from "react";

const ManualInputDrawer = ({ open, setOpen, handleScan, status = {} }) => {
	const [ticketCode, setTicketCode] = useState("");
	const { l10n } = useLocalization();

	const inputElement = useRef(null);
	const unfocusDiv = useRef(null);
	useEffect(() => {
		if (inputElement.current && open) {
			inputElement.current.focus();
		}
		if (!open) {
			unfocusDiv.current.focus();
		}
	}, [open]);
	useEffect(() => {
		if (status) close();
	}, [JSON.stringify(status)]);

	function handleKeyDown(event) {
		if (event.key === "Enter") {
			read();
		}
	}

	const read = () => {
		handleScan(null, { text: ticketCode });
	};

	function close() {
		setOpen(false);
		setTicketCode("");
	}
	return (
		<div id={`quick-scan-options`} className={open ? "open" : undefined} ref={unfocusDiv}>
			<button className="close" onClick={close}>
				<IconTimes /> <Localized id="manualinputdrawer-close" />
			</button>
			<div className="option-container">
				<h1 className="heading">
					<Localized id="manualinputdrawer-heading" />
				</h1>
				<div className="ticket-code">
					<label>
						<Localized id="manualinputdrawer-ticketcode" />
					</label>
					<TextInput
						value={ticketCode}
						onChange={e => setTicketCode(e.target.value)}
						placeholder={l10n.getString("manualinputdrawer-ticketcode-placeholder")}
						data-autofocus
						ref={inputElement}
						size="xl"
						className="manual-input"
						onKeyUp={handleKeyDown}
					/>
					<button className="read-button" disabled={!ticketCode} onClick={read}>
						<Localized id="manualinputdrawer-read-button" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManualInputDrawer;
