/** @format */

export const uniquesByTicketIdAndScanned = data => {
	// Step 1: Group the data by ticket_id
	const groupedData = data.reduce((groups, item) => {
		const key = item.ticket_id;
		if (!groups[key]) {
			groups[key] = [];
		}
		groups[key].push(item);
		return groups;
	}, {});

	// Step 2: For each group, find the object with the latest scanned value
	const filteredData = Object.values(groupedData).map(group => {
		const latestScanned = group.reduce((latest, item) => {
			if (!latest || new Date(item.scanned) > new Date(latest.scanned)) {
				return item;
			}
			return latest;
		}, null);

		return latestScanned;
	});

  const sortedScans = filteredData.slice().sort((a, b) => new Date(b.scanned) - new Date(a.scanned));

	return sortedScans;
};
