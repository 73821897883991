import { Localized } from "@fluent/react";
import SettingsWrapper from "Components/settings/wrapper/wrapper";
import { useEffect, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { SettingGroup } from "../dashboard/dashboard";
import { formatBytes } from 'util/bytes';
import { IconDatabase, IconPhone, IconWifi } from "Components/icons/icons";

import './deviceInfo.scss';


const DeviceInfo = () => {

    const [, selectors] = useDeviceSelectors(window.navigator.userAgent)

    const [effectiveType, setEffectiveType] = useState('-');
    const [downlink, setDownlink] = useState('-');
    const [rtt, setRtt] = useState('-');

    const [quota, setQuota] = useState(null);
    const [usage, setUsage] = useState(null);


    useEffect(() => {
        if (navigator.connection) {
            logNetworkInfo();
            navigator.connection.addEventListener('change', logNetworkInfo)
        }
        if (navigator.storage && navigator.storage.estimate) {
            getStorageEstimation();
        }

        return () => {
            if(navigator.connection) {
                navigator.connection.removeEventListener('change', logNetworkInfo);
            }
        }
    }, [])


    const logNetworkInfo = () => {
        setEffectiveType(navigator.connection.effectiveType);
        setDownlink(navigator.connection.downlink);
        setRtt(navigator.connection.rtt);
    }



    const [breakdown, setBreakdown] = useState({});

    async function getStorageEstimation() {
        const data = await navigator.storage.estimate();
        setQuota(data.quota || null);
        setUsage(data.usage || null);
        setBreakdown(data.usageDetails || null);
    }




    const {
        os,
        device,
        browser
    } = selectors;

    const { name: osName = "", version: osVersion = "" } = os;
    const { vendor = "", model = "" } = device;
    const { name: browserName = "", version: browserVersion = "" } = browser;

    return <SettingsWrapper id="device-info">
        <SettingGroup>
            <div className="group-heading">
                <IconPhone size="25" />
                <h1><Localized id="device-info-settings-heading" /></h1>
            </div>
            <InfoContainer
                title={<Localized id="device-info-device" />}
                info={`${vendor} ${model}`}
            />
            <InfoContainer
                title={<Localized id="device-info-os" />}
                info={`${osName}, versio:${osVersion}`}
            />
            <InfoContainer
                title={<Localized id="device-info-browser" />}
                info={`${browserName}, versio: ${browserVersion}`}
            />
        </SettingGroup>

        <SettingGroup>
            <div className="group-heading">
                <IconWifi size="25" />
                <h1> <Localized id="network-info-settings-heading" /></h1>
            </div>

            <InfoContainer
                title={<Localized id="network-info-connection" />}
                info={effectiveType}
            />
            <InfoContainer
                title={<Localized id="network-info-speed" />}
                info={`${downlink} Mb/s`}
            />
            <InfoContainer
                title={<Localized id="network-info-rtt" />}
                info={`${rtt} ms`}
            />

        </SettingGroup>

        <SettingGroup>
            <div className="group-heading">
                <IconDatabase size="25" />
                <h1> <Localized id="storage-info-settings-heading" /></h1>
            </div>

            <InfoContainer
                title={<Localized id="storage-info-usable" />}
                info={formatBytes(quota, 2)}
            />
            <InfoContainer
                title={<Localized id="storage-info-unusable" />}
                info={formatBytes(usage, 2)}
            />
        </SettingGroup>

    </SettingsWrapper>
}

export const InfoContainer = ({ title, info }) => {
    return <div className="info-container">
        <b>{title}</b>
        <p>{info}</p>
    </div>
}

export default DeviceInfo;