/** @format */

import { utils, writeFileXLSX } from "xlsx";

const generateMetadataKeys = data => {
	let keys = data
		.map(entry => entry.metadata)
		.filter(m => Object.keys(m).length > 0)
		.reduce((keyArr, curr) => {
			return [...new Set([...keyArr, ...Object.keys(curr)])];
		}, [])
		.map(k => `metadata.${k}`);

	return keys
};

export const writeExcelFile = (offlinedata, apikey) => {
	const metadataKeys = generateMetadataKeys(offlinedata);
	const noMetadataKeys = [...Object.keys(offlinedata[0]).filter(k => k !== "metadata")];
	const exportKeys = [...noMetadataKeys, ...metadataKeys];
	const exportData = [[...exportKeys]];

	offlinedata.forEach(entry => {
		const arr = [];
		exportKeys.forEach(k => {
			const splitted = k.split(".");
			if (splitted.length > 1) {
				const objKey = splitted[0];
				const propKey = splitted[1];
				arr.push(entry[objKey][propKey]);
			} else {
				arr.push(entry[k]);
			}
		});
		exportData.push(arr);
	});

	const wb = utils.book_new();
	const ws1 = utils.aoa_to_sheet(exportData);
	utils.book_append_sheet(wb, ws1, "Totals");
	writeFileXLSX(wb, `offlinescans-${apikey}.xlsx`);
};
