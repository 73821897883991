import { Localized } from '@fluent/react';
import { IconChevronRight } from 'Components/icons/icons';
import './paragraph.scss';

const Paragraph = ({ children, ...rest }) => {
    return <p className="onboard-paragraph">{children}</p>
}



export const OnboardNextPhaseTip = ({ children }) => {
    return <div className='onboard-next-tip'>
        <p className='onboard-paragraph next-tip'>
            <Localized id="onboard-next"/>
        </p>
        <p className='onboard-paragraph next-tip'>
            {children}
        </p>
    </div>
}

export default Paragraph;