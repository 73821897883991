import { IconClock } from 'Components/icons/icons';
import { LoggingContext } from 'Context/logging';
import moment from 'moment/moment';
import { useContext } from 'react';
import './deviceLog.scss';

const ScannerDeviceLog = () => {
    const { log = [] } = useContext(LoggingContext);
    return <div id="device-log">
        {
            log.map((entry, idx) => <div className='log-entry' key={`log-entry-${idx}`}>

                <IconClock size="30" className="entry-icon" />
                <div className='log-content'>
                    <p>{entry.event}</p>
                    <small>{moment(entry.timeStamp).format('DD.MM.YYYY hh:mm:ss')}</small>
                </div>

            </div>)
        }
    </div>
}


export default ScannerDeviceLog;