import ScannerNavigation from "Components/scanner/navigation/nagivation";
import SettingsHeader from "../header/settingsHeader";

import './wrapper.scss';

const SettingsWrapper = (props) => {
    return <div className="settings-wrapper">
        <SettingsHeader />
        <div className="content" id={props.id}>
            {props.children || null}
        </div>
    </div>

}

export default SettingsWrapper;