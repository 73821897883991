/** @format */
import "./refetchData.scss";
import { useParams } from "react-router";
import { Localized } from "@fluent/react";
import { ConfigurationsContext } from "Context/configurations";
import { useContext } from "react";
import { checkForNewTicketsAndScans, repopulateScansAndTicketsInIndexedDB } from "indexedDb/dexie";
import { useState } from "react";
import { useAsyncStatusHandler } from "hooks/asyncHandler";
import AsyncHandler from "Components/wrappers/apiCallHandler";
import { encodeQueryParams } from "util/api";
import { useEffect } from "react";
import ForwardButton from "Components/onboard/forwardButton";
import SettingsWrapper from "Components/settings/wrapper/wrapper";
import { SettingGroup } from "../dashboard/dashboard";
import { useNotificationContext } from "Context/notificationContext";

const RefetchData = () => {
	const { apikey } = useParams();
	const { boothMode } = useContext(ConfigurationsContext);
	const { handleErrorNotification } = useNotificationContext();

	const [tickets, setTickets] = useState([]);
	const [scans, setScans] = useState([]);
	const [newData, setNewData] = useState({ tickets: 0, scans: 0 });
	const [updated, setUpdated] = useState(false);
	const canBeUpdated = newData.tickets > 0 || newData.scans > 0;

	const { asyncState, startCall, resolveCall } = useAsyncStatusHandler();

	const { asyncState: asynctDbState, startCall: startDbCall, resolveCall: resolveDbCall } = useAsyncStatusHandler();
	const {
		asyncState: asyncCheckState,
		startCall: startCheckCall,
		resolveCall: resolveCheckCall,
	} = useAsyncStatusHandler();

	const populate = async () => {
		try {
			startDbCall();
			const { status: dbStatus } = await repopulateScansAndTicketsInIndexedDB(tickets, scans, !boothMode);
			resolveDbCall(dbStatus === 100 ? true : false, dbStatus === 100 ? 200 : 404);
			setUpdated(true);
		} catch (error) {
			console.error(error);
		}
	};

	const check = async () => {
		try {
			const { ok, ticketData, scanData } = await fetchTicketData();
			if (ok) {
				startCheckCall();
				const { status: dbStatus, data } = await checkForNewTicketsAndScans(
					ticketData.length,
					scanData.length,
					!boothMode
				);
				const { newTickets, newScans } = data;
				setNewData({ tickets: newTickets, scans: newScans });
				resolveCheckCall(dbStatus === 100 ? true : false, dbStatus === 100 ? 200 : 404);
			}
		} catch (error) {
			console.error(error);
		}
	};

	async function fetchTicketData() {
		startCall();
		let ticketData = [];
		let scanData = [];

		const ticketsResponse = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/tickets/${encodeQueryParams({ apikey: apikey })}`
		);
		if (ticketsResponse.ok) {
			ticketData = await ticketsResponse.json();
			setTickets(ticketData);
		} else {
			const errorData = await ticketsResponse.json();
			handleErrorNotification({
				title: (
					<Localized
						id="apirequest-error-tickets"
						vars={{ status: ticketsResponse.status }}
					>{`Error fetching tickets: ${ticketsResponse.status}`}</Localized>
				),
				children: JSON.stringify(errorData),
			});
		}

		if (!boothMode) {
			const scansResponse = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/tickets/scans/${encodeQueryParams({
					apikey: apikey,
				})}`
			);
			if (scansResponse.ok) {
				scanData = await scansResponse.json();
				setScans(scanData);
			} else {
				const errorData = await scansResponse.json();
				handleErrorNotification({
					title: (
						<Localized
							id="apirequest-error-scans"
							vars={{ status: scansResponse.status }}
						>{`Error fetching scans: ${scansResponse.status}`}</Localized>
					),
					children: JSON.stringify(errorData),
				});
			}

			const isOk = ticketsResponse.ok && scansResponse.ok;
			const status = ticketsResponse.ok && scansResponse.ok ? 200 : 404;
			resolveCall(isOk, status);
			return { ok: isOk, ticketData, scanData };
		} else {
			resolveCall(ticketsResponse.ok, ticketsResponse.status);
			return { ok: ticketsResponse.ok, ticketData, scanData };
		}
	}

	useEffect(() => {
		check();
	}, []);

	const newDataTitle = () => {
		if (!updated) {
			if (newData.tickets > 0 && newData.scans > 0) {
				return (
					<Localized
						id="refetch-new-scans-and-tickets"
						vars={{ tickets: newData.tickets, scans: newData.scans }}
					/>
				);
			}
			if (newData.tickets > 0) {
				return <Localized id="refetch-new-tickets" vars={{ tickets: newData.tickets }} />;
			}
			if (newData.scans > 0) {
				return <Localized id="refetch-new-scans" vars={{ scans: newData.scans }} />;
			}
			return <Localized id="refetch-new-nodata" />;
		} else {
			return <Localized id="refetch-databases-synced" />;
		}
	};

	return (
		<SettingsWrapper id="refetch-heading">
			<SettingGroup>
				<div className="refetch-content">
					<AsyncHandler {...asyncState}>
						<DataInfoContainer title={<Localized id="refetch-tickets-title" />}>
							<DataInfo description={<Localized id="refetch-tickets-total" />}>
								<b>
									<Localized id="amount-pcs" vars={{ amount: tickets.length }} />
								</b>
							</DataInfo>
							<DataInfo description={<Localized id="refetch-tickets-new" />}>
								<b>
									<Localized id="amount-pcs" vars={{ amount: newData.tickets }} />
								</b>
							</DataInfo>
						</DataInfoContainer>
						{!boothMode && (
							<DataInfoContainer title={<Localized id="refetch-scans-title" />}>
								<DataInfo description={<Localized id="refetch-scans-total" />}>
									<b>
										<Localized id="amount-pcs" vars={{ amount: scans.length }} />
									</b>
								</DataInfo>
								<DataInfo description={<Localized id="refetch-scans-new" />}>
									<b>
										<Localized id="amount-pcs" vars={{ amount: newData.scans }} />
									</b>
								</DataInfo>
							</DataInfoContainer>
						)}
						<DataInfoContainer title={newDataTitle()} className="refetch-status" />
					</AsyncHandler>
				</div>
			</SettingGroup>

			<div className="refetch-footer">
				<ForwardButton
					type="button"
					onClick={populate}
					disabled={!canBeUpdated || updated}
					className="refetch-update"
				>
					<Localized id="refetch-update" />
				</ForwardButton>
			</div>
		</SettingsWrapper>
	);
};

const DataInfoContainer = ({ title, className = "", children }) => {
	return (
		<div className={`data-info ${className}`}>
			<h3>{title}</h3>

			<div className="column">{children}</div>
		</div>
	);
};
const DataInfo = ({ description, children }) => {
	return (
		<div className="row">
			<p>{description}</p>
			<div className="children">{children}</div>
		</div>
	);
};

export default RefetchData;
