import LivetoLoader from "Components/loader/livetoLoader";
import { DB_RESPONSE_ERROR, DB_RESPONSE_OK } from "constants/dbStatuses";
import { entryDbError } from "constants/log-types";
import { LoggingContext } from "Context/logging";
import { useContext, useState } from "react";
import { useEffect } from "react";
import './dbAsyncHandler.scss';
import { Localized } from "@fluent/react";

const ErrorMessage = props => {
    return props.errorMessage ? props.errorMessage : <Localized id="wrapper-default-dberror" />;
}


const AsyncDatabaseCallHandler = (props) => {
    const { loading, status, size = "30", errorMessage } = props;
    const [innerLoading, setInnerLoading] = useState(false);
    const { addLogEntry } = useContext(LoggingContext);
    let timer = null;

    useEffect(() => {
        if (loading) {
            setInnerLoading(loading);
        }
        if (innerLoading && !loading) {
            timer = setTimeout(() => {
                setInnerLoading(false);
                clearTimeout(timer);
            }, 500)
        }
    }, [loading])

    useEffect(() => {
        if (status === DB_RESPONSE_ERROR) {
            addLogEntry(entryDbError(null))
        }
    }, [status])

    if (innerLoading) {

        return <div className="api-handler">
            <LivetoLoader size={size} />
        </div>

    } else {
        if (status === DB_RESPONSE_OK) {
            return props.children
        }
        if (status === null) {
            return <LivetoLoader size={size} />;
        }

        return <ErrorMessage errorMessage={errorMessage} />
    }
}
export default AsyncDatabaseCallHandler