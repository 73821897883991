/** @format */

import { useRef, useState } from "react";
import { IconCog, IconLightBulb, IconMute, IconTimes, IconUnmute, IconEdit } from "Components/icons/icons";

import { NOTFOUND, FLAG_YELLOW, FLAG_RED, FLAG_GREEN } from "constants/scanTypes";
import { useContext } from "react";
import { TicketStorageContext } from "Context/ticketStorageContext";
import moment from "moment/moment";
import { ConfigurationsContext } from "Context/configurations";

import { useCallback } from "react";
import { hapticFeedback } from "util/vibrate";
import QRReader from "Components/qr-reader/scanner";
import { Localized } from "@fluent/react";
import ScanInterruptModal from "./interruptModal";
import ScanOptionsDrawer from "./scanOptionsDrawer";
import { getTicketValidationDataFromDatabase } from "indexedDb/dexie";
import { DB_RESPONSE_ERROR } from "constants/dbStatuses";
import StatusRenderer from "./statusRenderer";
import ScanModeHumanReadable from "./scanModeHumanReadable";
import LaserReader from "Components/qr-reader/laser";

import "./scan.scss";
import ManualInputDrawer from "./manualInputDrawer";

const Scan = props => {
	const { scanSettings, userSettings, scanMode, modifyScanSettings } = useContext(ConfigurationsContext);

	const { mode, section, timeTolerance, expiredTicketAcceptance } = scanMode;
	const { lastSync, persistScanToBackendOrIndexedDb } = useContext(TicketStorageContext);
	const { keepScanPopupOpen, muted } = scanSettings;
	const [scanData, setScanData] = useState(null);
	const [scanDisabled, setScanDisabled] = useState(false);
	const [status, setStatus] = useState(null);
	const [optionsOpen, setOptionsOpen] = useState(false);
	const [manualInputOpen, setManualInputOpen] = useState(false);

	const [readerActive, setReaderActive] = useState(false);

	function resetStatus() {
		setStatus(null);
		setScanDisabled(false);
	}

	const readerRef = useRef(null);

	const scanDisabledRef = useRef();
	scanDisabledRef.current = scanDisabled;

	const modeRef = useRef();
	modeRef.current = scanMode.mode;

	const timeToleranceRef = useRef();
	timeToleranceRef.current = timeTolerance;

	const expiredTicketAcceptanceRef = useRef();
	expiredTicketAcceptanceRef.current = expiredTicketAcceptance;

	const sectionRef = useRef();
	sectionRef.current = typeof scanMode.section === "string" ? parseInt(scanMode.section) : scanMode.section;

	function manualDecline(ticketData, productData) {
		setStatus({
			flag: NOTFOUND,
			msg: <Localized id="ticket-status-declined" />,
			ticket: ticketData,
			product: productData,
		});
		setScanData(null);
	}

	function manualAccept(ticketData, productData) {
		setStatus({
			flag: 2,
			msg: <Localized id="ticket-status-accepted-manually" />,
			ticket: ticketData,
			product: productData,
		});
		setScanData(null);
	}

	function scanAccepted(msg, data, metadata) {
		return { flag: FLAG_GREEN, msg: msg, data, metadata };
	}
	function scanDeclined(msg, data = undefined) {
		return { flag: FLAG_RED, msg: msg, data: data };
	}
	function scanRequiresHumanAttention(msg, data, metadata, interruptInfo) {
		return {
			flag: FLAG_YELLOW,
			msg: msg,
			data: data,
			interruptInfo: interruptInfo,
			metadata: metadata,
		};
	}

	function productExpired(product, ticket) {
		if (ticket.valid_until && moment(ticket.valid_until).isBefore(moment())) {
			return true;
		}
		if (product.valid_until && moment(product.valid_until).isBefore(moment())) {
			return true;
		}

		return false;
	}
	function acceptExpiredTicket(product, ticket) {
		if (ticket.valid_until && moment(ticket.valid_until).isBefore(moment()) && expiredTicketAcceptanceRef.current) {
			return true;
		}
		if (
			product.valid_until &&
			moment(product.valid_until).isBefore(moment()) &&
			expiredTicketAcceptanceRef.current
		) {
			return true;
		}

		return false;
	}

	function productNotValidYet(product, ticket) {
		const ticketValidFrom = ticket.valid_from
			? moment(ticket.valid_from).subtract(parseInt(timeToleranceRef.current), "minutes")
			: null;
		const productValidFrom = product.valid_from
			? moment(product.valid_from).subtract(parseInt(timeToleranceRef.current), "minutes")
			: null;

		let validFrom = ticketValidFrom ? ticketValidFrom : productValidFrom;

		if (validFrom && moment(validFrom).isAfter(moment())) {
			return true;
		}
		return false;
	}

	const scanTemplate = (ticketId, scanType, section, device) => {
		return {
			ticket_id: ticketId,
			scanned: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
			scan_type: scanType, // [entry, exit], section, booth
			section_id: section, // section id (number), null
			device_id: device,
			device_label: device,
			metadata: {},
		};
	};

	function readerStatusCallback(active) {
		setReaderActive(active);
	}

	const generateStrictModalMsg = (isStrict, checkIsExpiredScannable, mData = {}) => {
		if (isStrict && checkIsExpiredScannable)
			return {
				msg: <Localized id="ticket-status-require-human-decision-expired" />,
				inputInfo: <Localized id="interrupt-lastread-in-expired" />,
				metadata: { ...mData, expired: true },
			};
		if (isStrict)
			return {
				msg: <Localized id="ticket-status-require-human-decision" />,
				inputInfo: <Localized id="interrupt-lastread-in" />,
				metadata: mData,
			};

		return {
			msg: <Localized id="ticket-status-not-valid_until" />,
			inputInfo: <Localized id="ticket-status-accept-expired" />,
			metadata: { expired: true },
		};
	};

	async function verifyTicket(code) {
		const { status: historyStatus, data } = await getTicketValidationDataFromDatabase(
			code,
			modeRef.current,
			sectionRef.current
		);
		// !SCENARIO: Ticket is not included in the tickets list that is fetched from the backend
		// Ticket is not found, return error flagging
		if (historyStatus === DB_RESPONSE_ERROR) return scanDeclined(<Localized id="ticket-status-not-found" />);

        const { ticketData, accesscontrol, scanHistoryForTargetArea = [], sectionName = null } = data;

		let scanningData = scanTemplate(
			ticketData.id,
			modeRef.current,
			sectionRef.current,
			scanSettings.device_name || "Unnamed device"
		);

        if (sectionRef.current !== null) {
			scanningData["metadata"]["sectionName"] = sectionName;
			scanningData["metadata"]["direction"] = "entry";
		}

		// !SCENARIO: TICKET IS NOT VALID YET, Products `valid_from` field is in the future time
		// Ticket not valid yet, return error flagging
		if (!data.productData && !data.ticketData.product_id && data.ticketData) {
            await persistScanToBackendOrIndexedDb(scanningData);
			return scanAccepted(<Localized id="ticket-status-not-valid_from" />, data);
		}

		// !SCENARIO: TICKET IS NOT VALID YET, Products `valid_from` field is in the future time
		// Ticket not valid yet, return error flagging
		if (productNotValidYet(data.productData.product, data.ticketData))
			return scanDeclined(<Localized id="ticket-status-not-valid_from" />, data);

		// !SCENARIO: TICKET HAS EXPIRED, Products `valid_until` is in the past
		// Ticket not valid anymore, return error flagging
		if (productExpired(data.productData.product, data.ticketData) && !expiredTicketAcceptanceRef.current)
			return scanDeclined(<Localized id="ticket-status-not-valid_until" />, data);

		const checkIsExpiredScannable = acceptExpiredTicket(data.productData.product, data.ticketData);

		

		// !SCENARIO: No access control defined in the current scan area selection (Entry / section)
		// No access control defined, ALWAYS use strict scanning for this
		if (!accesscontrol) {
			// !SCENARIO - Strict setting, returnting manual action modal if ticket is expired and scannable (expiredTicketAcceptance === true)
			if (checkIsExpiredScannable)
				return scanRequiresHumanAttention(
					<Localized id="ticket-status-not-valid_until" />,
					data,
					{ expired: true },
					<Localized id="ticket-status-accept-expired" />
				);
			// No previous scans, return successful scan for the target area
			if (scanHistoryForTargetArea.length === 0) {
				await persistScanToBackendOrIndexedDb(scanningData);
				return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
			}

			const lastScan = scanHistoryForTargetArea[scanHistoryForTargetArea.length - 1];
			// Look for the last scan, if it is an entry, interrupt the scanning.
			// Make the person scanning manually either scan the ticket IN, scan the ticket OUT, or DECLINE the scan based on the information provided by the scanner
			// Scanner offers information about the previous scans, which includes the place where they have been scanned, notes about the scans, and timestamps so the person scanning can do proper decision on the acceptance

			if (lastScan.scan_type === "entry" || lastScan?.metadata?.direction === "entry") {
				return scanRequiresHumanAttention(
					<Localized id="ticket-status-require-human-decision" />,
					data,
					scanningData["metadata"],
					<Localized id="interrupt-lastread-in" />
				);
			}

			await persistScanToBackendOrIndexedDb(scanningData);
			return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
		}

		const { scan_options: accessControlScanOptions, products: accessControlProductList } = accesscontrol;

		// ! Should not be going inside this if statement, this is error case but safeguarding it anyhow
		// If for some reason the scanner has not defined the scan options properly,
		// fallback to the product listing that is selected to the access control instance, and use strict settings for the scans ('Entry <-> Exit scanning type')
		if (!accessControlScanOptions["products"]) {
			if (accessControlProductList.includes(ticketData.id)) {
				const lastScan = scanHistoryForTargetArea[scanHistoryForTargetArea.length - 1];

				if (lastScan && (lastScan.scan_type === "entry" || lastScan?.metadata?.direction === "entry")) {
					return scanRequiresHumanAttention(
						<Localized id="ticket-status-require-human-decision" />,
						data,
						scanningData["metadata"],
						<Localized id="interrupt-lastread-in" />
					);
				}

				await persistScanToBackendOrIndexedDb(scanningData);
				return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
			}

			return scanDeclined(<Localized id="ticket-status-area-not-accepted" />);
		}

		const { access_control: scanningRules = null, amount: scannableAmount = 0 } =
			accessControlScanOptions["products"].find(scanOpts => scanOpts.variant === ticketData.product_id) || {};

		// !SCENARIO - Ticket is not listed in the access control
		// If access control does not include the currently scanned ticket, decline the scan, since the ticket should not have access to the area
		if (!scanningRules) return scanDeclined(<Localized id="ticket-status-area-not-accepted" />);
		switch (scanningRules) {
			// !SCENARIO - Loose setting on the access control for current ticket
			// Dont validate anyhow, this method is used to keep track of the passage of scanner. No need to validate further, just read the ticket.
			case "loose": {
				// !SCENARIO - Loose setting, returnting manual action modal if ticket is expired and scannable (expiredTicketAcceptance === true)
				if (checkIsExpiredScannable)
					return scanRequiresHumanAttention(
						<Localized id="ticket-status-not-valid_until" />,
						data,
						{ expired: true },
						<Localized id="ticket-status-accept-expired" />
					);
				await persistScanToBackendOrIndexedDb(scanningData);
				return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
			}

			// !SCENARIO - Amount based validation on the access control for current ticket
			// Validate the ticket by the amount it is usable to the target area.
			case "amount": {
				if (scanHistoryForTargetArea.length >= scannableAmount) {
					return scanDeclined(
						<Localized
							id="ticket-status-already-used"
							vars={{ usedAmount: scanHistoryForTargetArea.length, availableAmount: scannableAmount }}
						/>
					);
				}
				// !SCENARIO - Amount based validation, returnting manual action modal if ticket is expired and scannable (expiredTicketAcceptance === true)
				if (checkIsExpiredScannable)
					return scanRequiresHumanAttention(
						<Localized id="ticket-status-not-valid_until" />,
						data,
						{ expired: true },
						<Localized id="ticket-status-accept-expired" />
					);
				await persistScanToBackendOrIndexedDb(scanningData);
				// Do we want to show more info about this scanning? For example how many times left and so on?
				return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
			}

			// !SCENARIO - Strict setting on the access control for current ticket
			// Strict case, validate the ticket passage, if its in or not in the target area.
			default: {
				const lastScan = scanHistoryForTargetArea[scanHistoryForTargetArea.length - 1];
				const isStrict =
					lastScan && (lastScan.scan_type === "entry" || lastScan?.metadata?.direction === "entry");
				if (isStrict || checkIsExpiredScannable) {
					const { msg, inputInfo, metadata } = generateStrictModalMsg(
						isStrict,
						checkIsExpiredScannable,
						scanningData["metadata"]
					);
					return scanRequiresHumanAttention(msg, data, metadata, inputInfo);
				}

				await persistScanToBackendOrIndexedDb(scanningData);
				return scanAccepted(<Localized id="ticket-status-accepted" />, data, scanningData["metadata"]);
			}
		}
	}

	const handleScan = useCallback(
		async (err, result) => {
			if (!scanDisabledRef.current && result) {
				setScanDisabled(true);
				scanDisabledRef.current = true;
				hapticFeedback([200]);
				const { flag, msg, data, interruptInfo, metadata } = await verifyTicket(result.text);
				const manuallyShowPopup = keepScanPopupOpen && flag !== FLAG_RED;
				// Ticket has some issues, require manual acceptance, or the user has selected to always use manual acceptance
				if (manuallyShowPopup || flag === FLAG_YELLOW) {
					setScanData({ ...data, interruptInfo, metadata });
				} else {
					setStatus({
						flag,
						msg,
						ticket: data?.ticketData,
						product: data?.productData?.product,
					});
				}
			}
		},
		[scanDisabled, mode, section]
	);

	const scanIsBlocked = scanDisabledRef.current ? true : false;

	return (
		<div className="scanner-scan">
			<ScanOptionsDrawer open={optionsOpen} setOpen={setOptionsOpen} />
			<ManualInputDrawer
				open={manualInputOpen}
				setOpen={setManualInputOpen}
				handleScan={handleScan}
				status={status}
			/>
			{!status && <ScanModeHumanReadable />}
			{status && <StatusRenderer status={status} resetStatus={resetStatus} />}

			{scanData && (
				<ScanInterruptModal scanData={scanData} manualDecline={manualDecline} manualAccept={manualAccept} />
			)}

			{!scanData && !status && (
				<QRReader
					width={"100%"}
					height={"100%"}
					blocked={scanIsBlocked}
					ref={readerRef}
					onUpdate={handleScan}
					readerStatusCallback={readerStatusCallback}
				/>
			)}
			<LaserReader onUpdate={handleScan} blocked={scanIsBlocked} />

			{/*<div className={`qr-lines${status ? ` status-${status}` : ''}`} />*/}
			<div className={`actions ${userSettings.handedness}`}>
				{readerActive && (
					<>
						<button className="action-button close-reader" onClick={() => readerRef?.current?.stopReader()}>
							<IconTimes size="30" />
						</button>
						<button className={`action-button`} onClick={() => readerRef?.current?.toggleTorch()}>
							<IconLightBulb size="30" />
						</button>
					</>
				)}

				<button className={`action-button `} onClick={() => setManualInputOpen(true)}>
					<IconEdit size="25" />
				</button>
				<button
					className={`action-button mute${muted ? " on" : ""} `}
					onClick={() => modifyScanSettings("muted", !muted)}
				>
					{muted ? <IconMute size="25" /> : <IconUnmute size="25" />}
				</button>
				<button className={`action-button `} onClick={() => setOptionsOpen(true)}>
					<IconCog size="25" />
				</button>
			</div>

			<div className="last-sync-timestamp">
				<Localized
					id="scanview-last-fetch"
					vars={{ lastUpdate: moment(lastSync).format("DD.MM.YYYY HH:mm:ss") }}
				/>
			</div>
		</div>
	);
};

export default Scan;
