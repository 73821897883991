/** @format */

import { Localized } from "@fluent/react";
import ForwardButton from "Components/onboard/forwardButton";
import OnboardHeading from "Components/onboard/heading";
import OnboardWrapper from "Components/onboard/onboardWrapper";
import Paragraph from "Components/onboard/paragraph";
import SupportInfo from "Components/onboard/supportInfo";
import AsyncHandler from "Components/wrappers/apiCallHandler";
import { ConfigurationsContext } from "Context/configurations";
import { useNotificationContext } from "Context/notificationContext";
import { boothPath, scannerPath } from "constants/paths";
import { useAsyncStatusHandler } from "hooks/asyncHandler";
import { writeScansToIndexedDb, writeTicketsToIndexedDb } from "indexedDb/dexie";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { encodeQueryParams } from "util/api";

const AppImportTicketData = () => {
	const { boothMode } = useContext(ConfigurationsContext);

	const { apikey } = useParams();
	const history = useHistory();

	const { asyncState, startCall, resolveCall } = useAsyncStatusHandler();
    const { handleErrorNotification } = useNotificationContext();

	const [tickets, setTickets] = useState([]);
	const [scans, setScans] = useState([]);

	const {
		asyncState: ticketDbState,
		startCall: startTicketDbCall,
		resolveCall: resolveTicketDbCall,
	} = useAsyncStatusHandler();

	const {
		asyncState: scansDbState,
		startCall: startScansDbCall,
		resolveCall: resolveScansDbCall,
	} = useAsyncStatusHandler();

	async function submit() {
		startTicketDbCall();
		const { status: tStatus, data: tData } = await writeTicketsToIndexedDb(tickets);
		resolveTicketDbCall(tStatus === 100 ? true : false, tStatus === 100 ? 200 : 404);
		if (!boothMode) {
			startScansDbCall();
			const { status: sStatus, data: sData } = await writeScansToIndexedDb(scans);
			resolveScansDbCall(sStatus === 100 ? true : false, sStatus === 100 ? 200 : 404);
		}

		history.push(boothMode ? boothPath(apikey) : scannerPath(apikey));
	}
	async function fetchTicketData(apikey) {
		startCall();

		const ticketsResponse = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/tickets/${encodeQueryParams({ apikey: apikey })}`
		);
		if (ticketsResponse.ok) {
			const ticketData = await ticketsResponse.json();
			setTickets(ticketData);
		} else {
			const errorData = await ticketsResponse.json();
			handleErrorNotification({
				title: (
					<Localized
						id="apirequest-error-tickets"
						vars={{ status: ticketsResponse.status }}
					>{`Error fetching tickets: ${ticketsResponse.status}`}</Localized>
				),
				children: JSON.stringify(errorData),
			});
		}
		if (!boothMode) {
			const scansResponse = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/scanner/tickets/scans/${encodeQueryParams({
					apikey: apikey,
				})}`
			);
			if (scansResponse.ok) {
				const scansData = await scansResponse.json();
				setScans(scansData);
			} else {
				const errorData = await scansResponse.json();
				handleErrorNotification({
					title: (
						<Localized
							id="apirequest-error-scans"
							vars={{ status: scansResponse.status }}
						>{`Error fetching scans: ${scansResponse.status}`}</Localized>
					),
					children: JSON.stringify(errorData),
				});
			}
			resolveCall(ticketsResponse.ok && scansResponse.ok, ticketsResponse.ok && scansResponse.ok ? 200 : 404);
			return;
		}
		resolveCall(ticketsResponse.ok, ticketsResponse.status);
	}

	function renderText() {
		if (ticketDbState.loading) {
			return <Localized id="onboard-import-tip-tickets-loading" />;
		}
		if (scansDbState.loading) {
			return <Localized id="onboard-import-tip-scans-loading" />;
		}
		return <Localized id="onboard-import-tip" />;
	}

	useEffect(() => {
		fetchTicketData(apikey);
	}, []);
	return (
		<OnboardWrapper>
			<OnboardHeading>
				<Localized id="onboard-import-heading" />
			</OnboardHeading>
			<AsyncHandler {...asyncState}>
				<SupportInfo
					title={<Localized id="onboard-import-tickets-title" />}
					description={<Localized id="onboard-import-tickets-desc" />}
				>
					<b>
						<Localized id="amount-pcs" vars={{ amount: tickets.length }} />
					</b>
				</SupportInfo>
				{!boothMode && (
					<SupportInfo
						title={<Localized id="onboard-import-scans-title" />}
						description={<Localized id="onboard-import-scans-desc" />}
					>
						<b>
							<Localized id="amount-pcs" vars={{ amount: scans.length }} />
						</b>
					</SupportInfo>
				)}
			</AsyncHandler>

			<ForwardButton onClick={submit} type="button" />
			<Paragraph>{renderText()}</Paragraph>
		</OnboardWrapper>
	);
};

export default AppImportTicketData;
