/** @format */

import { useInterval } from "@mantine/hooks";
import { scannerConfigurationUrl, scansUrl, ticketsUrl } from "constants/apiurls";
import { DB_RESPONSE_OK } from "constants/dbStatuses";
import { expiredPath } from "constants/paths";
import { ConfigurationsContext } from "Context/configurations";
import { NetworkStatusContext } from "Context/networkStatus";
import { useNotificationContext } from "Context/notificationContext";
import { TicketStorageContext } from "Context/ticketStorageContext";
import {
	clearOfflineTicketsFromIndexedDb,
	getOfflineTicketsFromIndexedDb,
	requestTicketUpdateDataFromIndexedDb,
	writeScansToIndexedDb,
	writeTicketsToIndexedDb,
} from "indexedDb/dexie";
import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { encodeQueryParams } from "util/api";

const AutoUpdater = () => {
	const history = useHistory();
	const { scanSettings, persistScannerConfigurations } = useContext(ConfigurationsContext);
	const { autoUpdate, updateInterval } = scanSettings;

	const online = useContext(NetworkStatusContext);
	const {
		lastSync,
		setLastSync,
		updateTicketAmountFromDatabaseToState,
		updateUniqueScansAmountFromDatabaseToState,
		updateUnsyncedScanAmountFromDatabase,
	} = useContext(TicketStorageContext);

	const { handleErrorNotification, handleSuccessNotification } = useNotificationContext();

	const [updateDelay, setUpdateDelay] = useState(updateInterval);

	const [currentTimestamp, setCurrentTimestamp] = useState(null);
	const interval = useInterval(() => setCurrentTimestamp(moment().format("YYYY-MM-DDTHH:mm:ss")), updateInterval);

	async function fetchScannerConfigurationAndUpdateIndexedDb(configuration) {
		const { apikey = "" } = configuration;
		if (apikey) {
			const configurationResponse = await fetch(`${scannerConfigurationUrl}${encodeQueryParams({ apikey })}`);

			if (configurationResponse.ok) {
				const incomingData = await configurationResponse.json();
				// Handle changed fields separately, if events has changed, fetch new event data
				await persistScannerConfigurations(incomingData);

				// If other data has changed, just add it to the indexedDB
			} else {
				history.push(expiredPath);
			}
		}
	}

	async function fetchTicketsAndUpdateIndexedDb(apikey, after) {
		if (apikey) {
			const ticketsResponse = await fetch(`${ticketsUrl}${encodeQueryParams({ apikey, after })}`);

			if (ticketsResponse.ok) {
				const ticketData = await ticketsResponse.json();
				if (ticketData.length > 0) {
					const dbResponse = await writeTicketsToIndexedDb(ticketData);
					// TODO What do we do with this? Tell user that new tickets have been added?
				}
			} else {
				const errorData = await ticketsResponse.json();
				handleErrorNotification({
					title: (
						<Localized
							id="apirequest-error-tickets"
							vars={{ status: ticketsResponse.status }}
						>{`Error fetching tickets: ${ticketsResponse.status}`}</Localized>
					),
					children: JSON.stringify(errorData),
				});
			}
		}
		updateTicketAmountFromDatabaseToState();
	}

	async function fetchScansAndUpdateIndexedDb(apikey, after) {
		if (apikey) {
			const scansResponse = await fetch(`${scansUrl}${encodeQueryParams({ apikey: apikey, after: after })}`);
			if (scansResponse.ok) {
				const scansData = await scansResponse.json();
				if (scansData.length > 0) {
					const dbResponse = await writeScansToIndexedDb(scansData);
					// TODO What do we do with this?
				}
			} else {
				const errorData = await scansResponse.json();
				handleErrorNotification({
					title: (
						<Localized
							id="apirequest-error-scans"
							vars={{ status: scansResponse.status }}
						>{`Error fetching scans: ${scansResponse.status}`}</Localized>
					),
					children: JSON.stringify(errorData),
				});
			}
		}
		updateUniqueScansAmountFromDatabaseToState();
	}

	async function postOfflineTicketsAndUpdateIndexedDb(apikey) {
		if (apikey) {
			// Post offline tickets, and delete them from indexeddb if they succeeded. Afterwards, fetch the new tickets

			const ticketsResponse = await getOfflineTicketsFromIndexedDb();
			if (ticketsResponse.status === DB_RESPONSE_OK) {
				const ticketsToSyncWithBackend = [...ticketsResponse.data];

				if (ticketsToSyncWithBackend.length > 0) {
					for (let ticket of ticketsToSyncWithBackend) {
						if (ticket.section_id === null) {
							delete ticket.section_id;
							delete ticket.id;
						}
					}

					const postedScansResponse = await fetch(scansUrl, {
						method: "POST",
						headers: { "Content-Type": "Application/json" },
						body: JSON.stringify({
							apikey: apikey,
							tickets: ticketsToSyncWithBackend,
							device: scanSettings.device_name || "Unnamed device",
						}),
					});

					if (postedScansResponse.ok) {
						// Cleanup offline tickets
						await clearOfflineTicketsFromIndexedDb();
					} else {
						const errorData = await postedScansResponse.json();
						handleErrorNotification({
							title: (
								<Localized
									id="apirequest-error-tickets-post"
									vars={{ status: postedScansResponse.status }}
								>{`Error posting tickets: ${postedScansResponse.status}`}</Localized>
							),
							children: JSON.stringify(errorData),
						});
					}
				}
			}
		}
		updateUnsyncedScanAmountFromDatabase();
	}

	async function updateData() {
		if (!online) return;

		const { status, data } = await requestTicketUpdateDataFromIndexedDb();
		if (status === DB_RESPONSE_OK) {
			const { configuration, lastScanned, lastTicket } = data;

			// Fetch scanner configuration from API, and update the data to indexedDB and state
			await fetchScannerConfigurationAndUpdateIndexedDb(configuration);

			// Check tickets that have been scanned offline from indexedDB, and update the information to API
			await postOfflineTicketsAndUpdateIndexedDb(configuration.apikey);

			const { booth_mode } = configuration;
			if (!booth_mode) {
				// Fetch tickets from API, and update new tickets to indexedDB and state
				await fetchTicketsAndUpdateIndexedDb(configuration.apikey, lastTicket);

				// Fetch scans from API, and update new scans to indexedDB and state
				await fetchScansAndUpdateIndexedDb(configuration.apikey, lastScanned);
			}
		}
	}

	useEffect(() => {
		if (updateInterval !== updateDelay) {
			interval.stop();
			setUpdateDelay(updateInterval);
			interval.start();
		}
	}, [updateInterval]);

	function stopInterval() {
		if (interval.active) {
			interval.stop();
		}
	}
	useEffect(() => {
		if (autoUpdate) {
			interval.start();
		}
		if (!autoUpdate) {
			stopInterval();
		}

		return stopInterval();
	}, [autoUpdate]);

	useEffect(() => {
		const timeToUpdate = moment(lastSync).add(updateInterval, "milliseconds");

		if (moment().isAfter(timeToUpdate) || !lastSync) {
			interval.stop();
			updateData();

			// Set the timestamp of when the data sync is handled.
			setLastSync(moment().format("YYYY-MM-DDTHH:mm:ss"));

			interval.start();
		}
	}, [currentTimestamp, online]);

	return null;
};

export default AutoUpdater;
