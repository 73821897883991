/** @format */

import React, { useRef, useContext } from "react";
import { useParams } from "react-router";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as paths from "constants/paths";
import Scan from "views/scannerApplication/scan/scan";

import "./app.scss";
import Home from "views/scannerApplication/home/home";
import ScannerHeader from "Components/scanner/header/header";
import Tickets from "views/scannerApplication/tickets/tickets";
import NetworkStatusContext from "Context/networkStatus";
import TicketStorageContextProvider from "Context/ticketStorageContext";
import ScannerNavigation from "Components/scanner/navigation/nagivation";
import LoggingContextProvider from "Context/logging";
import ScannerDeviceLog from "views/scannerApplication/deviceLog/deviceLog";

// Onboarding views
import LandingPage from "views/landingPage/landingPage";
import AppSetupConfig from "views/Onboard/4_App_Setup_config/appSetupConfig";
import AppReadConfig from "views/Onboard/5_App_read_config/appReadConfig";
import AppLoadConfig from "views/Onboard/6_App_load_config/appLoadConfig";
import AppConfigInfo from "views/Onboard/7_App_config_info/appConfigInfo";
import AppName from "views/Onboard/8_App_name/appName";
import AppImportTicketData from "views/Onboard/9_App_import_ticket_data/appImportTicketData";
import StartOnboard from "views/Onboard/1_Start/startOnboard";
import ExpiredConfiguration from "views/expiredConfiguration/expired";
import SettingsDashboard from "views/settings/dashboard/dashboard";
import DeviceInfo from "views/settings/deviceInfo/deviceInfo";
import UserSettings from "views/settings/userSettings/userSettings";
import AutoUpdater from "Components/autoUpdater/autoUpdater";
import ConfigurationsContextProvider, { ConfigurationsContext } from "Context/configurations";
import AppSettings from "views/settings/appSettings/appSettings";
import BoothHeader from "Components/booth/header/header";
import BoothHomeView from "views/boothApplication/home/home";
import BoothScan from "views/boothApplication/scan/scan";
import BoothContacts from "views/boothApplication/contacts/contacts";
import ResetSettings from "views/resetSettings/resetSettings";
import TicketsSingleTicket from "views/scannerApplication/ticketsSingleTicket/ticketsSingleTicket";
import ScannerEventList from "views/scannerApplication/events/events";
import ScannerEventDetails from "views/scannerApplication/events/eventDetail";
import RefetchData from "views/settings/refetchData/refetchData";
import { NotificationContextProvider } from "Context/notificationContext";

const App = props => {
	return (
		<LoggingContextProvider>
			<NotificationContextProvider>
				<NetworkStatusContext>
					<ConfigurationsContextProvider>
						<Router>
							<Switch>
								{/* Onboarding routes */}
								<Route path={paths.onboardPath}>
									<OnboardRoutes />
								</Route>

								{/* Scanner application */}
								<Route path={paths.scannerPath(":apikey")}>
									<ScannerApplication />
								</Route>

								{/* Booth application */}

								<Route path={paths.boothPath(":apikey")}>
									<BoothApplication />
								</Route>

								<Route path={paths.settingsPath(":apikey")}>
									<SettingsApplication />
								</Route>

								<Route path={paths.expiredPath}>
									<ExpiredConfiguration />
								</Route>

								<Route path={paths.clearAppSettingsPath(":apikey")}>
									<ResetSettings />
								</Route>

								<Route path={paths.refetchDataPath(":apikey")}>
									<RefetchData />
								</Route>
								{/* Starting path when ever using the application. Can be injected a new configuration via url, thats why theres optional apikey param */}
								<Route path={"/:apikey?"}>
									<LandingPage />
								</Route>
							</Switch>
						</Router>
					</ConfigurationsContextProvider>
				</NetworkStatusContext>
			</NotificationContextProvider>
		</LoggingContextProvider>
	);
};

const OnboardRoutes = props => {
	return (
		<Switch>
			<Route path={paths.appStartOnboard(`:apikey?`)}>
				<StartOnboard />
			</Route>
			<Route path={paths.appOnboardSetupConfigPath(`:apikey?`)}>
				<AppSetupConfig />
			</Route>

			<Route path={paths.appOnboardReadConfigPath(`:apikey?`)}>
				<AppReadConfig />
			</Route>

			<Route path={paths.appOnboardLoadConfigurationPath(":apikey")}>
				<AppLoadConfig />
			</Route>

			<Route path={paths.appOnboardConfigurationInfoPath(":apikey")}>
				<AppConfigInfo />
			</Route>

			<Route path={paths.appOnboardBoothNamePath(":apikey")}>
				<AppName />
			</Route>

			<Route path={paths.appOnboardACNamePath(":apikey")}>
				<AppName />
			</Route>

			<Route path={paths.appOnboardAcImportDataPath(":apikey")}>
				<AppImportTicketData />
			</Route>
		</Switch>
	);
};

const ScannerApplication = props => {
	const { apikey } = useParams();
	const { scannerConfiguration } = useContext(ConfigurationsContext);
	if (scannerConfiguration.apikey !== apikey) return <Redirect to={paths.appStartOnboard(apikey)} />;

	return (
		<TicketStorageContextProvider>
			<div id="scannerApp">
				<AutoUpdater />
				<ScannerHeader />
				<div id="scanner-content">
					<Switch>
						<Route path={paths.scannerPath(":apikey")} exact>
							<Home />
						</Route>
						<Route path={paths.scannerScannedTicketPath(":apikey", ":ticket_code")} exact>
							<TicketsSingleTicket />
						</Route>
						<Route path={paths.scannerScannedPath(":apikey")}>
							<Tickets />
						</Route>
						<Route path={paths.scannerScanPath(":apikey")}>
							<Scan />
						</Route>
						<Route path={paths.scannerEventDetailsPath(":apikey", ":slug")}>
							<ScannerEventDetails />
						</Route>
						<Route path={paths.scannerEventsPath(":apikey")}>
							<ScannerEventList />
						</Route>
					</Switch>
				</div>
				<ScannerNavigation />
			</div>
		</TicketStorageContextProvider>
	);
};

const SettingsApplication = props => {
	const { apikey } = useParams();
	const { scannerConfiguration } = useContext(ConfigurationsContext);
	if (scannerConfiguration.apikey !== apikey) return <Redirect to={paths.appStartOnboard(apikey)} />;

	return (
		<Switch>
			<Route path={paths.settingsPath(":apikey")} exact>
				<SettingsDashboard />
			</Route>

			<Route path={paths.userSettingsPath(":apikey")}>
				<UserSettings />
			</Route>

			<Route path={paths.appSettingsPath(":apikey")}>
				<AppSettings />
			</Route>
			<Route path={paths.deviceInfoPath(":apikey")}>
				<DeviceInfo />
			</Route>

			<Route path={paths.scannerDeviceLogPath(":apikey")}>
				<ScannerDeviceLog />
			</Route>
		</Switch>
	);
};

const BoothApplication = props => {
	const { apikey } = useParams();
	const { scannerConfiguration } = useContext(ConfigurationsContext);
	if (scannerConfiguration.apikey !== apikey) return <Redirect to={paths.appOnboardBoothNamePath(apikey)} />;

	const scrollRef = useRef();

	return (
		<TicketStorageContextProvider>
			<div id="scannerApp">
				<AutoUpdater />

				<BoothHeader />
				<div id="scanner-content" ref={scrollRef}>
					<Switch>
						<Route path={paths.boothPath(":apikey")} exact>
							<BoothHomeView />
						</Route>

						<Route path={paths.boothScanPath(":apikey")} exact>
							<BoothScan />
						</Route>

						<Route path={paths.boothScannedTicketsPath(":apikey")} exact>
							<BoothContacts />
						</Route>
					</Switch>
				</div>
			</div>
		</TicketStorageContextProvider>
	);
};

export default App;
