/** @format */

import { Localized } from "@fluent/react";
import { NavLink } from "react-router-dom";
import "./forwardButton.scss";

const ForwardButton = ({ children, to, disabled, type = "link", onClick = null, className = "" }) => {
	const text = children || <Localized id="onboard-continue-button" />;
	function linkOnClick(e) {
		if (disabled) {
			e.preventDefault();
		}
		return;
	}
	if (type === "button") {
		return (
			<button className={`forward-button ${className}`} disabled={disabled} onClick={onClick}>
				{text}
			</button>
		);
	}
	return (
		<NavLink className={`forward-button ${className}`} to={to} disabled={disabled} onClick={linkOnClick}>
			{text}
		</NavLink>
	);
};

export default ForwardButton;
