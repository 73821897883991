/** @format */

export const rootPath = apikey => `/${apikey}`;

// Scanner application
export const scannerPath = apikey => `/accesscontrol/${apikey}`;
export const scannerScanPath = apikey => `${scannerPath(apikey)}/scan`;
export const scannerScannedPath = apikey => `${scannerPath(apikey)}/scanned`;
export const scannerScannedProductsPath = apikey => `${scannerScannedPath(apikey)}/products/list`;
export const scannerScannedProductsProductPath = (apikey, product_id) =>
	`${scannerScannedProductsPath(apikey)}/${product_id}`;
export const scannerScannedProductsEventPath = (apikey, event_slug) =>
	`${scannerScannedProductsPath(apikey)}/event/${event_slug}`;
export const scannerScannedExternalTicketsPath = (apikey, event_slug) =>
	`${scannerScannedProductsPath(apikey)}/external/${event_slug}`;
export const scannerScannedTicketPath = (apikey, ticket_code) => `${scannerScannedPath(apikey)}/${ticket_code}`;
export const scannerEventsPath = apikey => `${scannerPath(apikey)}/events`;
export const scannerEventDetailsPath = (apikey, slug) => `${scannerPath(apikey)}/events/${slug}`;
export const scannerStoragePath = apikey => `${scannerPath(apikey)}/storage`;
export const expiredPath = `/configuration/expired-configuration`;

// Onboarding routes
export const onboardPath = "/onboard";
export const appStartOnboard = apikey => `${onboardPath}/start/${apikey}`;
export const appOnboardInfoPath = apikey => `${onboardPath}/info/${apikey}`;
export const appOnboardPermissionsPath = apikey => `${onboardPath}/permissions/${apikey}`;
export const appOnboardSetupConfigPath = apikey => `${onboardPath}/setup/${apikey}`;
export const appOnboardReadConfigPath = apikey => `${onboardPath}/read/${apikey}`;
export const appOnboardLoadConfigurationPath = apikey => onboardPath + `/app-load-config/${apikey}`;
export const appOnboardConfigurationInfoPath = apikey => onboardPath + `/app-config-info/${apikey}`;

// Booth onboard
export const appOnboardBoothNamePath = apikey => `${onboardPath}/booth/name/${apikey}`;

// Access control onboard
export const appOnboardACNamePath = apikey => `${onboardPath}/accesscontrol/name/${apikey}`;
export const appOnboardAcImportDataPath = apikey => `${onboardPath}/accesscontrol/import-data/${apikey}`;

// Settings path
export const settingsPath = apikey => `/settings/${apikey}`;
export const userSettingsPath = apikey => `${settingsPath(apikey)}/user-settings`;
export const appSettingsPath = apikey => `${settingsPath(apikey)}/app-settings`;
export const deviceInfoPath = apikey => `${settingsPath(apikey)}/device-info`;
export const networkInfoPath = apikey => `${settingsPath(apikey)}/network-info`;
export const storageInfoPath = apikey => `${settingsPath(apikey)}/storage-info`;
export const scannerDeviceLogPath = apikey => `${settingsPath(apikey)}/logs`;

// Booth application
export const boothPath = apikey => `/booth/${apikey}`;
export const boothScanPath = apikey => `${boothPath(apikey)}/scan`;
export const boothScannedTicketsPath = apikey => `${boothPath(apikey)}/scanned`;

// Remove settings path
export const clearAppSettingsPath = apikey => `/reset-application/${apikey}`;

// Refetch data path
export const refetchDataPath = apikey => `/refetch-data/${apikey}`;
