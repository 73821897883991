import { IconAngleLeft } from "Components/icons/icons";
import { useHistory } from "react-router-dom"
import './onboardWrapper.scss';

const OnboardWrapper = ({ children, id = null, showBackButton = true }) => {
    const history = useHistory();
    function previous() {
        history.goBack();
    }
    return <div className="onboard-view" id={id ? id : undefined}>
        {showBackButton && <button onClick={previous} className="previous-button">
            <IconAngleLeft size="25" className="icon" />
            <span className="text">{'Edellinen'}</span>
        </button>}

        {children}
    </div>
}

export default OnboardWrapper;